
import React, { useState } from 'react';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/joy';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FAQ = () => {
  const [expanded, setExpanded] = useState(false);

  const faqs = [
    {
      question: 'Why should I invest in ETFs?',
      answer:
        'ETFs offer diversified exposure to various markets and asset classes at a low cost. They are flexible, liquid, and allow investors to invest in a wide array of assets without the need to buy each individually.',
    },
    {
      question: 'Why should I invest in multiple asset classes?',
      answer:
        'Investing in multiple asset classes helps to diversify your portfolio, reducing risk and increasing the potential for returns. Different asset classes react differently to market conditions, which can help stabilize your portfolio over time.',
    },
    {
      question: 'What is the best way to split money across assets?',
      answer:
        'The best way to split money across assets depends on your risk tolerance, investment goals, and time horizon. A diversified approach that balances stocks, bonds, commodities, and other assets is generally recommended to optimize risk and reward.',
    },
    {
      question: 'How does the AlgoInvest Core Fund do the split?',
      answer:
        'The AlgoInvest Core Fund uses a proprietary algorithm to dynamically allocate investments across various asset classes. It takes into account market trends, risk factors, and economic indicators to determine the optimal split for maximizing returns while managing risk.',
    },
    {
      question: 'How does AlgoInvest know this split is best?',
      answer:
        'AlgoInvest uses advanced quantitative models and historical data analysis to identify patterns and correlations between different asset classes. The algorithm is continuously tested and refined to adapt to changing market conditions, ensuring the split remains optimal.',
    },
    {
      question: 'What differentiates this algorithm from others?',
      answer:
        'Our algorithm stands out due to its ability to dynamically adjust to market changes in real-time, its focus on minimizing costs, and its use of extensive data and machine learning to refine asset allocation strategies. It is designed to be transparent, robust, and adaptable, providing an edge over more static or less data-driven approaches.',
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#f9f9f9', 
        py: { xs: 4, md: 8 },
        px: { xs: 2, md: 6 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <Box sx={{ textAlign: 'left', maxWidth: '25%' }}>
        <Typography level="h4" sx={{ fontSize: '3.5rem', fontWeight: '700', color: '#2f3b52', mb: 1 }}>
          Know More
        </Typography>
        <Typography level="h4" sx={{ fontSize: '3.5rem', fontWeight: '700', color: '#2f3b52', mb: 2 }}>
          Invest Better
        </Typography>
        <Typography level="subtitle1" sx={{ fontSize: '2rem', color: '#5a6b7d', mb: 4 }}>
          Find quick solutions and expert advice to our most frequently asked questions.
        </Typography>
      </Box>
      <Box sx={{ flex: 1, ml: { md: 4 } }}>
        {faqs.map((faq, index) => (
          <Accordion
            key={index}
            expanded={expanded === index}
            onChange={() => setExpanded(expanded === index ? false : index)}
            sx={{
              backgroundColor: '#ffffff', // Use a subtle green or white shade
              borderRadius: '30px',
              padding: '10px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              mb: 2,
              '&:before': {
                display: 'none',
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
              sx={{ px: 2, py: 1 }}
            >
              <Typography sx={{ fontSize: '1.8rem', color: '#2f3b52' }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 2, py: 1 }}>
              <Typography sx={{ fontSize: '1.5rem', color: '#5a6b7d' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
