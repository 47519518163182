import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Input, Button, Alert } from '@mui/joy';
import TabBar from '../../components/tabs/TabBar';
import { API_BASE_URL } from '../../constants';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const { token } = useParams(); // Assume the token is passed as a URL parameter
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    if (!password || !confirmPassword) {
      setError('Please fill in all fields.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/reset-password/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token, password }),
      });

      if (response.ok) {
        setMessage('Your password has been reset successfully.');
        // Optionally, navigate to the login page after a delay
        setTimeout(() => navigate('/login'), 3000);
      } else {
        throw new Error('Failed to reset password.');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('Failed to reset password. Please try again.');
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f8f9fa', display: 'flex', flexDirection: 'column' }}>
      <TabBar />

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: { xs: '2rem', md: '4rem' }, 
          paddingBottom: '2rem',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            padding: { xs: '2rem 1.5rem', md: '3rem 2.5rem' },
            maxWidth: 400,
            width: '100%',
            backgroundColor: 'inherit', 
            textAlign: 'center',
          }}
          noValidate
        >
          <Typography level="h4" align="center" sx={{ color: '#2c3e50', fontSize: '2rem', marginBottom: '2rem', fontWeight: 600 }}>
            Reset Password
          </Typography>

          {/* New Password Field */}
          <Box sx={{ position: 'relative', marginBottom: '1.5rem' }}>
            <Input
              name="password"
              type="password"
              placeholder="Enter your new password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={Boolean(error) && !password}
              required
              sx={{
                width: '100%',
                padding: '0.75rem',
                fontSize: '1rem',
                borderRadius: '8px',
                borderColor: Boolean(error) && !password ? 'danger.600' : '#ccc',
                '&:focus': { borderColor: 'primary.600' },
              }}
            />
            {Boolean(error) && !password && (
              <Typography level="body3" color="danger.600" sx={{ mt: 0.5 }}>
                New password is required.
              </Typography>
            )}
          </Box>

          {/* Confirm Password Field */}
          <Box sx={{ position: 'relative', marginBottom: '1.5rem' }}>
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirm your new password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              error={Boolean(error) && password !== confirmPassword}
              required
              sx={{
                width: '100%',
                padding: '0.75rem',
                fontSize: '1rem',
                borderRadius: '8px',
                borderColor: Boolean(error) && password !== confirmPassword ? 'danger.600' : '#ccc',
                '&:focus': { borderColor: 'primary.600' },
              }}
            />
            {Boolean(error) && password !== confirmPassword && (
              <Typography level="body3" color="danger.600" sx={{ mt: 0.5 }}>
                Passwords do not match.
              </Typography>
            )}
          </Box>

          {/* Success Message */}
          {message && (
            <Alert color="success" variant="soft" sx={{ marginBottom: '1rem' }}>
              {message}
            </Alert>
          )}

          {/* Error Message */}
          {error && (
            <Alert color="danger" variant="soft" sx={{ marginBottom: '1rem' }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            variant="solid"
            color="success"
            fullWidth
            sx={{
              padding: '0.75rem',
              fontSize: '1rem',
              backgroundColor: '#4caf50',
              borderRadius: '6px',
              '&:hover': { backgroundColor: '#45a049' },
            }}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPassword;
