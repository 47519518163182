import React from 'react';
import { Box, Typography, FormControl, Select, Option } from '@mui/joy';

const ProfileHeader = ({ userName, selectedAccount, accounts, onAccountChange }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        backgroundColor: '#fff',
        padding: '1.5rem',
        marginBottom: '2rem',
        borderRadius: 'md',
        boxShadow: 'sm',
        color: '#333', // Grey color for text
      }}
    >
      <Typography
        level="h4"
        component="h1"
        sx={{
          fontSize: '1.8rem',
          fontWeight: '600',
          color: '#000', // Black color for emphasis
        }}
      >
        Welcome, {userName}
      </Typography>

      {accounts && accounts.length > 0 && (
        <FormControl
          sx={{
            minWidth: 200,
            backgroundColor: '#f2f2f2', // Light grey background for the dropdown
            borderRadius: '30px',
          }}
        >
          <Select
            placeholder="Select Account"
            value={selectedAccount ? selectedAccount.id : ''}
            onChange={(e, newValue) => onAccountChange(newValue)}
            sx={{
              fontSize: '1.15rem',
              padding: '8px 20px',
              color: '#333', // Grey color for text
              fontWeight: '500',
              borderRadius: '30px',
              '&:hover': {
                backgroundColor: '#e6e6e6', // Slightly darker grey on hover
              },
            }}
          >
            {accounts.map((account) => (
              <Option key={account.id} value={account.id}>
                {account.name} (${account.balance.toLocaleString()})
              </Option>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default ProfileHeader;

