import React from 'react';
import { Box, Button, Link, Sheet } from '@mui/joy';
import { Link as RouterLink } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';

const InternalTabBar = () => {
  // Navigation links
  const navLinks = [
    { label: 'Core', href: '/core-fund' },
    { label: 'Equities', href: '/equities' },
    { label: 'Fixed Income', href: '/fixed-income' },
    { label: 'Commodities', href: '/commodities' },
    { label: 'Real Estate', href: '/real-estate' },
    { label: 'Rho', href: '/rho' },
    { label: 'Beta', href: '/beta' },
    { label: 'Alpha', href: '/alpha' },
    { label: 'Taxes', href: '/tax-efficiencies' },
  ];

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  return (
    <Sheet
      variant="solid"
      color="neutral"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '11%',
        bgcolor: '#F2F4F3',
        zIndex: 1200,
        overflowY: 'auto',
        boxShadow: '2px 0 5px rgba(0,0,0,0.1)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      {/* Logo and Navigation Links */}
      <Box sx={{ p: 2 }}>
        {/* Logo */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Link
            href="/"
            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}
          >
            <img src={logo} alt="Logo" style={{ height: '45px' }} />
          </Link>
        </Box>

        {/* Navigation Links */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {navLinks.map((link) => (
            <Link
              key={link.label}
              href={link.href}
              underline="none"
              sx={{
                fontWeight: '500',
                fontSize: '1.15rem',
                color: 'text.primary',
                pl: 2,
                py: 1,
                borderLeft: window.location.pathname === link.href ? '2px solid black' : '',
                backgroundColor: window.location.pathname === link.href ? '#D9D9D9' : 'transparent',
                transition: 'background-color 0.2s ease, border-left 0.2s ease',
                '&:hover': {
                  backgroundColor: '#e6f7f2',
                  borderLeft: '2px solid #40826D',
                },
              }}
            >
              {link.label}
            </Link>
          ))}
        </Box>
      </Box>

      {/* Auth Buttons */}
      <Box sx={{ p: 2 }}>
        <Button
          component={RouterLink}
          to="/profile" // Adjusted to point to the profile page instead of login
          variant="outlined"
          color="neutral"
          sx={{
            fontSize: '1.15rem',
            padding: '10px 15px',
            fontWeight: 'bold',
            borderColor: '#40826D',
            borderRadius: '30px',
            color: '#40826D',
            backgroundColor: 'transparent',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#e6f7f2',
              borderColor: '#40826D',
            },
            mb: 2,
            width: '80%',
          }}
        >
          Profile
        </Button>
        <Button
          onClick={handleLogout}
          variant="solid"
          sx={{
            fontSize: '1.15rem',
            padding: '10px 15px',
            fontWeight: 'bold',
            bgcolor: '#40826D',
            borderColor: '#ffffff',
            borderRadius: '30px',
            color: '#ffffff',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#478778',
            },
            width: '80%',
          }}
        >
          Log Out
        </Button>
      </Box>
    </Sheet>
  );
};

export default InternalTabBar;
