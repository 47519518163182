import axios from 'axios';
import { API_BASE_URL } from '../constants';

export const fetchUserData = async () => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const response = await axios.get(`${API_BASE_URL}/user-data/`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data:', error);
    throw error;
  }
};

export const updateUserData = async (userData) => {
  const accessToken = localStorage.getItem('access_token');
  try {
    const response = await axios.put(`${API_BASE_URL}/update-user-data/`, userData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error updating user data:', error);
    throw error;
  }
};

const getAuthHeader = () => {
  const accessToken = localStorage.getItem('access_token');
  return { 'Authorization': `Bearer ${accessToken}` };
};

export const changePassword = async (oldPassword, newPassword) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/change-password/`, {
      old_password: oldPassword,
      new_password: newPassword
    }, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    throw error;
  }
};

export const enable2FA = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/enable-2fa/`, {}, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error enabling 2FA:', error);
    throw error;
  }
};

export const verify2FA = async (code) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/verify-2fa/`, {
      code: code
    }, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error verifying 2FA:', error);
    throw error;
  }
};

export const getAccounts = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/accounts/`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching accounts:', error);
    throw error;
  }
};

export const addAccount = async (accountData) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/add-account/`, accountData, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error adding account:', error);
    throw error;
  }
};

export const removeAccount = async (accountId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/remove-account/${accountId}/`, {
      headers: getAuthHeader()
    });
    return response.data;
  } catch (error) {
    console.error('Error removing account:', error);
    throw error;
  }
};