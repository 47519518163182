// src/data/assetClassData.js

const expectedReturns = {
  'Core Fund': 0.08,
  'Equities': 0.10,
  'Fixed Income': 0.04,
  'Real Estate': 0.06,
  'Commodities': 0.05,
};

const expectedVolatilities = {
  'Core Fund': 0.12,
  'Equities': 0.18,
  'Fixed Income': 0.05,
  'Real Estate': 0.15,
  'Commodities': 0.20,
};

export const generateInvestmentData = (
  granularity,
  initialValue,
  label,
) => {
  // Assign default expectedReturn and expectedVolatility
  let expectedReturn = 0.08; // default value
  let expectedVolatility = 0.12; // default value

  // Check if label is in expectedReturns
  if (expectedReturns[label]) {
    expectedReturn = expectedReturns[label];
    expectedVolatility = expectedVolatilities[label];
  } else {
    // For ETFs or subgroups, assign random expected returns and volatilities
    expectedReturn = Math.random() * 0.1 + 0.05; // Between 5% and 15%
    expectedVolatility = Math.random() * 0.1 + 0.1; // Between 10% and 20%
  }
  const now = new Date();
  let startDate;
  let interval;
  let points;
  let pointsPerYear;

  switch (granularity) {
    case '10Y':
      startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
      interval = 'month';
      points = 121;
      pointsPerYear = 12;
      break;
    case '5Y':
      startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
      interval = 'month';
      points = 61;
      pointsPerYear = 12;
      break;
    case '1Y':
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      interval = 'week';
      points = 53;
      pointsPerYear = 52;
      break;
    case '6M':
      startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      interval = 'week';
      points = 27;
      pointsPerYear = 52;
      break;
    case '1M':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      interval = 'day';
      points = 31;
      pointsPerYear = 365;
      break;
    case '1W':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      interval = 'hour';
      points = 169;
      pointsPerYear = 8760;
      break;
    case '1D':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      interval = 'hour';
      points = 25;
      pointsPerYear = 8760;
      break;
    default:
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      interval = 'week';
      points = 53;
      pointsPerYear = 52;
  }

  const data = [];
  let currentDate = new Date(startDate);
  let investmentValue = initialValue;



  for (let i = 0; i < points; i++) {
    const randomReturn = (expectedReturn / pointsPerYear) + (expectedVolatility / Math.sqrt(pointsPerYear)) * (Math.random() - 0.5);
    investmentValue *= Math.exp(randomReturn);

    data.push({ x: new Date(currentDate), y: parseFloat(investmentValue.toFixed(2)) });

    switch (interval) {
      case 'month':
        currentDate.setMonth(currentDate.getMonth() + 1);
        break;
      case 'week':
        currentDate.setDate(currentDate.getDate() + 7);
        break;
      case 'day':
        currentDate.setDate(currentDate.getDate() + 1);
        break;
      case 'hour':
        currentDate.setHours(currentDate.getHours() + 1);
        break;
    }
  }

  return data;
};

export const getRandomStatistics = () => {
  return {
    returns: `${(Math.random() * 20 - 5).toFixed(2)}%`,
    volatility: `${(Math.random() * 15 + 5).toFixed(2)}%`,
    sharpe: (Math.random() * 2 + 0.5).toFixed(2),
  };
};

export const generateCorrelationMatrix = (labels) => {
  const matrix = [['-', ...labels]];

  for (let i = 0; i < labels.length; i++) {
    const row = [labels[i]];
    for (let j = 0; j < labels.length; j++) {
      if (i === j) {
        row.push(1);
      } else if (j < i) {
        row.push(matrix[j + 1][i + 1]); // Symmetric value
      } else {
        // Generate random correlation between -1 and 1
        row.push(parseFloat((Math.random() * 2 - 1).toFixed(2)));
      }
    }
    matrix.push(row);
  }
  return matrix;
};


export const assetClasses = ['Core Fund', 'Equities', 'Fixed Income', 'Real Estate', 'Commodities'];

export const granularityOptions = [
  { label: '10 Years', value: '10Y' },
  { label: '5 Years', value: '5Y' },
  { label: '1 Year', value: '1Y' },
  { label: '6 Months', value: '6M' },
  { label: '1 Month', value: '1M' },
  { label: '1 Week', value: '1W' },
  { label: '1 Day', value: '1D' },
];

const getRandomPerformanceData = (etfs) => {
  const periods = ['1D', '1W', '1M', '6M', '1Y', '5Y'];
  return etfs.map((etf) => ({
    symbol: etf.symbol,
    performance: periods.reduce((acc, period) => {
      acc[period] = getRandomNumber(
        period === '1D' ? -2 : period === '1W' ? -10 : period === '1M' ? -20 : period === '6M' ? -30 : period === '1Y' ? -40 : -50,
        period === '1D' ? 5 : period === '1W' ? 15 : period === '1M' ? 25 : period === '6M' ? 35 : period === '1Y' ? 45 : 55,
        2
      );
      return acc;
    }, {}),
  }));
};

const getRandomNumber = (min, max, decimals = 2) =>
  parseFloat((Math.random() * (max - min) + min).toFixed(decimals));

const createETFList = (etfSymbols) => {
  return etfSymbols.map((symbol) => ({
    symbol,
    description: `${symbol} ETF`,
    company: 'Various',
    totalAssets: `$${(Math.random() * 50 + 1).toFixed(2)}B`,
    price: `$${(Math.random() * 500 + 10).toFixed(2)}`,
    performance: { '1Y': getRandomNumber(-10, 20) }, 
  }));
};

const getRandomCorrelations = (etfSymbols) => {
  const correlations = [];
  const headers = ['-'].concat(etfSymbols);
  correlations.push(headers);
  for (let i = 0; i < etfSymbols.length; i++) {
    const row = [etfSymbols[i]];
    for (let j = 0; j < etfSymbols.length; j++) {
      if (i === j) {
        row.push(1);
      } else if (j < i) {
        row.push(correlations[j + 1][i + 1]);
      } else {
        const corr = parseFloat((Math.random() * 0.8 + 0.2).toFixed(2));
        row.push(corr);
      }
    }
    correlations.push(row);
  }
  return correlations;
};

const getTopPerformer = (performanceData) => {
  const oneYearPerformances = performanceData.map((etf) => ({
    symbol: etf.symbol,
    performance: etf['1Y'],
  }));
  const topPerformer = oneYearPerformances.reduce((max, etf) =>
    etf.performance > max.performance ? etf : max
  );
  return {
    symbol: topPerformer.symbol,
    performance: `${topPerformer.performance}%`,
  };
};

const populateSubgroups = (parentGroup, etfListObject, categoryDescription) => {
  for (const [subgroupName, etfSymbols] of Object.entries(etfListObject)) {
    const etfs = createETFList(etfSymbols);
    const performanceData = getRandomPerformanceData(etfs);
    const correlations = getRandomCorrelations(etfSymbols);
    const topPerformer = getTopPerformer(performanceData);

    parentGroup.subgroups[subgroupName] = {
      description: generateSubgroupDescription(subgroupName, etfs, categoryDescription),
      etfs,
      statistics: getRandomStatistics(),
      performanceData, 
      correlations,
      topPerformer,
    };
  }
};

const generateSubgroupDescription = (subgroupName, etfs, categoryDescription) => {
  const etfSymbols = etfs.map((etf) => etf.symbol).join(', ');
  const readableName = subgroupName.replace(/_/g, ' ');
  return `${readableName.charAt(0).toUpperCase() + readableName.slice(1)} ETFs focus on ${categoryDescription} related to ${readableName.replace(/_/g, ' ')}. This group includes ETFs such as ${etfSymbols}, providing investors with exposure to this specific market segment.`;
};

export const aggregateGroupData = (group) => {
  const allPerformanceData = [];
  const allStatistics = [];
  Object.values(group.subgroups).forEach((subgroup) => {
    if (subgroup.performanceData) {
      allPerformanceData.push(...subgroup.performanceData);
    }
    if (subgroup.statistics) {
      allStatistics.push(subgroup.statistics);
    }
  });
  group.performanceData = allPerformanceData;
  group.statistics = getRandomStatistics();
};

const REGION_BASED_ETF_LISTS = {
  "global_broad": ['ACWI', 'VT', 'CWI'],
  "global_specialized": ['GSEW', 'XT', 'DWLD'],
  "north_america_developed": ['VXUS', 'VEU'],
  "europe_developed": ['IDEV', 'EPOL', 'IEFA'],
  "asia_developed": ['EWN', 'FLJP'],
  "emerging_markets_major": ['EEM', 'VWO', 'IEMG', 'EMQQ'],
  "emerging_markets_diversified_small_cap": ['GWX', 'VSS', 'SCHE'],
  "emerging_markets_asia_country": ['EPHE', 'INDY', 'INDA'],
  "emerging_markets_latin_america_country": ['ARGT', 'ILF', 'AFK', 'EPU'],
  "emerging_markets_africa_middle_east_country": ['EZA', 'FLBR', 'QAT'],
  "emerging_markets_small_cap": ['EWX', 'DGS'],
  "asia_pacific_developed": ['EWJ', 'VPL', 'JPXN'],
  "asia_pacific_emerging_major": ['EWY', 'EWA', 'EWW'],
  "asia_pacific_sector_focus": ['FXI', 'FJP', 'CQQQ'],
  "china_focus": ['KWEB', 'CNYA'],
  "developed_europe_broad": ['VGK', 'EWU', 'IEV'],
  "developed_europe_country_specific": ['EWK', 'EWQ', 'EWG', 'EWI', 'EWL', 'FLKR'],
  "other_europe_focus": ['EWO', 'SPEU'],
};

const SIZE_BASED_ETF_LISTS = {
  "large_cap_growth": ['SPY', 'IVV', 'VTI', 'IVW', 'VOOG', 'IWF'],
  "large_cap_value": ['SCHG', 'IWV', 'IUSG', 'VV', 'VTV', 'FENY'],
  "large_cap_tech_focus": ['QQQ', 'SPYG', 'TQQQ', 'FTEC'],
  "large_cap_diversified": ['SPDW', 'SCHD', 'SCHM', 'SCHX', 'VYM'],
  "large_cap_specialized": ['DVY', 'SPXL', 'RSP', 'IUSV'],
  "mid_cap_growth": ['MDYG', 'IWP', 'IJH', 'MDY'],
  "mid_cap_value": ['VTWV', 'MDYV', 'SPSM', 'SCHA'],
  "small_cap_core": ['IJR', 'VB', 'IWN'],
  "small_cap_value": ['FNDA', 'IJS', 'EVX', 'VTWO'],
  "small_cap_growth": ['IJT', 'VBK', 'VXF'],
  "small_mid_cap_diversified": ['ISMD', 'IQDF'],
  "small_mid_cap_growth_focus": ['SMLF', 'IWO', 'MJ'],
  "small_mid_cap_value_focus": ['VOE', 'VBR'],
};

const SECTOR_BASED_ETF_LISTS = {
  "banking_financial_services": ['XLF', 'KIE', 'IAT', 'KBWR'],
  "investment_brokerages": ['VFH', 'FNCL', 'IYG', 'KCE'],
  "heavy_industry_machinery": ['XLI', 'XLB'],
  "transportation_logistics": ['IYJ', 'NFTY'],
  "consumer_electronics": ['XLK', 'IXN'],
  "semiconductors": ['SOXX', 'SMH'],
  "digital_services": ['IYW', 'QTEC'],
  "digital_communication_infrastructure": ['VOX', 'SKYY', 'ARKK'],
  "core_cybersecurity": ['HACK', 'CIBR'],
  "blockchain_security": ['BLOK', 'BLCN'],
  "advanced_security": ['DTEC'],
  "network_security": ['ROBT'],
  "emerging_tech_cybersecurity": ['META', 'SOCL'],
  "biotechnology": ['XBI', 'BBP', 'IBB', 'ARKG', 'BBH', 'FBT'],
  "medical_devices_wellness": ['IHI', 'XHE', 'VHT'],
  "consumer_discretionary": ['XLY', 'VCR', 'IYK'],
  "consumer_staples": ['XLP', 'XHS', 'UDOW'],
  "renewable_energy": ['ICLN', 'TAN', 'QCLN'],
  "clean_technology": ['ACES', 'PBD', 'GRID', 'LIT'],
  "video_gaming": ['GAMR'],
  "commercial_aerospace": ['JETS', 'ITA'],
  "space_exploration": ['ARKX'],
  "consumer_staples_food": ['FTXG', 'HIPS', 'PBJ', 'RETL'],
  "traditional_automotive": ['CARZ', 'CARS'],
  "electric_vehicles": ['HAIL', 'DRIV'],
  "residential_construction": ['ITB', 'XHB'],
  "infrastructure_development": ['PAVE', 'NAIL'],
  "digital_payments": ['IPAY'],
  "blockchain_cryptocurrency": ['FINX'],
  "essential_infrastructure": ['PHO', 'PSCI'],
  "utilities": ['VPU', 'XLU'],
};

const FACTOR_BASED_ETF_LISTS = {
  "bull_market_leveraged": ['UPRO', 'UMDD', 'SOXL', 'TECL', 'ROM'],
  "sector_specific_leveraged": ['LABU', 'EDC', 'DRN'],
  "broad_market_inverse": ['SPXU', 'YINN'],
  "sector_specific_inverse": ['LABD', 'DPST', 'GII'],
  "esg_focus": ['ETHO', 'ESGU', 'SDG', 'NULG'],
  "growth": ['VUG', 'MGK', 'QARP'],
  "quality": ['QUAL', 'USMV', 'SPLG'],
  "value_vs_growth": ['VLUE', 'MTUM', 'IWD'],
};

const FIXED_INCOME_BASED_ETF_LISTS = {
  "us_and_municipal_government_bonds": ['TIP', 'SCHZ', 'MUB'],
  "corporate_bonds": ['LQD', 'VCSH', 'IGSB'],
  "high_yield_corporate_bonds": ['JNK', 'HYG'],
  "emerging_markets_bonds": ['EMB', 'IUSB'],
  "international_fixed_income": ['CEW', 'FXB', 'FXE', 'FXY', 'FXF'],
  "currency_focused_etfs": ['UUP'],
  "international_government_bonds": ['IFRA', 'PID', 'VIDI', 'DIM'],
  "aggregate_bonds": ['BND', 'AGG'],
};

const COMMODITIES_BASED_ETF_LISTS = {
  "gold_precious_metals": ['GLD', 'SLV', 'PALL'],
  "silver_specialty_metals": ['SIL', 'SILJ', 'SIVR', 'PLTM'],
  "energy_commodities": ['GSG', 'DBC', 'USO', 'UNG'],
  "renewable_energy_resources": ['WOOD', 'RNRG'],
  "industrial_metals": ['COPX', 'CRBN', 'SLX'],
  "agriculture_commodities": ['SOYB', 'PDN', 'FAN'],
  "niche_specialized_commodities": ['BDRY', 'URA', 'FTXH'],
  "mining_exploration": ['XME', 'PICK', 'REMX'],
};

const REAL_ESTATE_BASED_ETF_LISTS = {
  "domestic_real_estate": ['SCHH', 'IYR'],
  "international_real_estate": ['RWX', 'VNQI'],
  "commercial_real_estate": ['XLRE', 'PSR'],
  "residential_real_estate": ['REZ', 'VNQ'],
  "diversified_global_real_estate": ['REET', 'WPS', 'SRVR'],
};

export const equitiesData = {
  name: 'Equities',
  description: 'Equities represent ownership in companies and offer growth potential.',
  longDescription:
    'Equities, also known as stocks, represent ownership shares in corporations. They offer investors the potential for capital appreciation and dividends. Equities can be classified based on various factors such as region, size, sector, and investment style, providing a diverse range of investment opportunities.',
  subclasses: ['Region', 'Size', 'Sector', 'Factor'],
  performanceData: getRandomPerformanceData(createETFList(['ACWI', 'VT', 'CWI'])), // Example for global_broad
  groups: {
    Region: {
      description: 'Equities grouped by geographical regions.',
      subgroups: {},
    },
    Size: {
      description: 'Equities categorized by the market capitalization of companies.',
      subgroups: {},
    },
    Sector: {
      description: 'Equities categorized by industry sectors.',
      subgroups: {},
    },
    Factor: {
      description: 'Equities classified based on investment factors or styles.',
      subgroups: {},
    },
  },
};

export const fixedIncomeData = {
  name: 'Fixed Income',
  description: 'Investments in debt securities that provide regular income.',
  longDescription:
    'Fixed income securities provide a return in the form of fixed periodic payments and the eventual return of principal at maturity. They are typically issued by governments, corporations, and other entities to finance operations and projects. Fixed income investments are considered less risky than equities and are used to diversify portfolios, generate income, and preserve capital.',
  subclasses: ['Type'],
  performanceData: getRandomPerformanceData(createETFList(FIXED_INCOME_BASED_ETF_LISTS["us_and_municipal_government_bonds"])), // Example
  groups: {
    Type: {
      description: 'Fixed income investments categorized by type.',
      subgroups: {},
    },
  },
};

export const commoditiesData = {
  name: 'Commodities',
  description: 'Investments in physical goods like metals, energy resources, and agricultural products.',
  longDescription:
    'Commodities are basic goods used in commerce that are interchangeable with other goods of the same type. Investors can gain exposure to commodities through commodity-linked ETFs, which invest in futures contracts or the physical goods themselves. Commodities can act as a hedge against inflation and add diversification to a portfolio.',
  subclasses: ['Category'],
  performanceData: getRandomPerformanceData(createETFList(COMMODITIES_BASED_ETF_LISTS["gold_precious_metals"])), // Example
  groups: {
    Category: {
      description: 'Commodities categorized by type of goods.',
      subgroups: {},
    },
  },
};

export const realEstateData = {
  name: 'Real Estate',
  description: 'Investments in real estate through Real Estate Investment Trusts (REITs).',
  longDescription:
    'Real estate investments provide exposure to the property market without the need to directly own or manage properties. REITs are companies that own, operate, or finance income-generating real estate across various property sectors. They offer investors regular income streams, diversification, and long-term capital appreciation potential.',
  subclasses: ['Region'],
  performanceData: getRandomPerformanceData(createETFList(REAL_ESTATE_BASED_ETF_LISTS["domestic_real_estate"])), // Example
  groups: {
    Region: {
      description: 'Real estate investments categorized by geographical focus.',
      subgroups: {},
    },
  },
};

populateSubgroups(equitiesData.groups.Region, REGION_BASED_ETF_LISTS, 'geographical regions');
populateSubgroups(equitiesData.groups.Size, SIZE_BASED_ETF_LISTS, 'company sizes');
populateSubgroups(equitiesData.groups.Sector, SECTOR_BASED_ETF_LISTS, 'industry sectors');
populateSubgroups(equitiesData.groups.Factor, FACTOR_BASED_ETF_LISTS, 'investment factors or styles');
populateSubgroups(fixedIncomeData.groups.Type, FIXED_INCOME_BASED_ETF_LISTS, 'types of fixed income securities');
populateSubgroups(commoditiesData.groups.Category, COMMODITIES_BASED_ETF_LISTS, 'types of commodities');
populateSubgroups(realEstateData.groups.Region, REAL_ESTATE_BASED_ETF_LISTS, 'geographical regions in real estate');

Object.values(equitiesData.groups).forEach((group) => {
  aggregateGroupData(group);
});

Object.values(fixedIncomeData.groups).forEach((group) => {
  aggregateGroupData(group);
});

Object.values(commoditiesData.groups).forEach((group) => {
  aggregateGroupData(group);
});

Object.values(realEstateData.groups).forEach((group) => {
  aggregateGroupData(group);
});

export default {
  equitiesData,
  fixedIncomeData,
  commoditiesData,
  realEstateData,
  generateInvestmentData,
  getRandomStatistics,
  generateCorrelationMatrix,
  assetClasses,
  granularityOptions,
};
