import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Input, Button, Checkbox, Alert, Link } from '@mui/joy';
import TabBar from '../../components/tabs/TabBar';
import { API_BASE_URL } from '../../constants';

const SignUp = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    streetAddr: '',
    cityAddr: '',
    stateAddr: '',
    countryAddr: '',
    zipCode: '',
    dob: '',
    phoneNumber: '',
    agreeTerms: false,
  });

  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
    setError('');
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (formData.password !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    if (!formData.agreeTerms) {
      setError('Please agree to the terms and conditions');
      return;
    }

    setError('');

    const userData = {
      firstname: formData.firstName,
      lastname: formData.lastName,
      email: formData.email,
      password: formData.password,
      street_addr: formData.streetAddr,
      city_addr: formData.cityAddr,
      state_addr: formData.stateAddr,
      country_addr: formData.countryAddr,
      zip_code: formData.zipCode,
      dob: formData.dob,
      phone_number: formData.phoneNumber,
    };

    try {
      const response = await fetch(`${API_BASE_URL}/register/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        console.log('Sign up successful!');
        navigate('/application-submitted');
      } else {
        setError(data.message || 'An error occurred during sign up');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred during sign up');
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#f8f9fa', display: 'flex', flexDirection: 'column' }}>
      <TabBar />

      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: { xs: '2rem', md: '4rem' },
          paddingBottom: '2rem',
        }}
      >
        <Box
          component="form"
          onSubmit={handleSignup}
          sx={{
            padding: { xs: '2rem 1.5rem', md: '3rem 2.5rem' },
            maxWidth: 450,
            width: '100%',
            backgroundColor: 'inherit', // Match the surrounding background
            textAlign: 'center',
          }}
          noValidate
        >
          <Typography level="h4" align="center" sx={{ color: '#2f3b52', fontSize: '2.4rem', marginBottom: '2rem', fontWeight: 'bold' }}>
            Sign Up
          </Typography>

          {['firstName', 'lastName', 'email', 'password', 'confirmPassword', 'streetAddr', 'cityAddr', 'stateAddr', 'countryAddr', 'zipCode', 'dob', 'phoneNumber'].map((field) => (
            <Box sx={{ marginBottom: '1.5rem' }} key={field}>
              <Input
                name={field}
                type={field === 'password' || field === 'confirmPassword' ? 'password' : field === 'dob' ? 'date' : 'text'}
                placeholder={field.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                value={formData[field]}
                onChange={handleChange}
                required={['firstName', 'lastName', 'email', 'password', 'confirmPassword', 'dob'].includes(field)}
                sx={{
                  width: '100%',
                  height: '3.5rem',
                  padding: '1rem',
                  fontSize: '1.3rem',
                  borderRadius: '25px',
                  borderColor: '#ccc',
                  '&:focus': { borderColor: 'primary.600' },
                }}
              />
            </Box>
          ))}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1.5rem' }}>
            <Checkbox
              name="agreeTerms"
              checked={formData.agreeTerms}
              onChange={handleChange}
              color="success"
              sx={{ marginRight: '0.5rem' }}
            />
            <Typography component="span" sx={{ color: '#40826D', cursor: 'pointer' }} onClick={() => setFormData((prev) => ({ ...prev, agreeTerms: !prev.agreeTerms }))}>
              I agree to the{' '}
              <Link component={RouterLink} to="/terms-and-conditions" target="_blank" rel="noopener noreferrer" sx={{ color: 'primary.600' }}>
                terms and conditions
              </Link>
              .
            </Typography>
          </Box>

          {error && (
            <Alert color="danger" variant="soft" sx={{ marginBottom: '1rem' }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            variant="solid"
            color="success"
            fullWidth
            sx={{
              padding: '0.75rem',
              fontSize: '1.4rem',
              fontWeight: 'bold',
              backgroundColor: '#40826D',
              borderRadius: '30px',
              '&:hover': { backgroundColor: '#478778' },
            }}
          >
            Next
          </Button>

          <Typography level="body2" align="center" sx={{ marginTop: '1.5rem', fontSize: '0.875rem' }}>
            Already have an account?{' '}
            <Link component={RouterLink} to="/login" variant="body2" color="primary">
              Log In
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;