import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link, List, ListItem } from '@mui/joy';

// Section Component (Reusable)
const Section = ({ title, subtitle, content, subsections }) => (
  <Box sx={{ marginBottom: '4rem' }}>
    <Typography level="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
      {title}
    </Typography>
    {subtitle && (
      <Typography level="h6" gutterBottom sx={{ color: '#34495e', marginTop: '1rem' }}>
        {subtitle}
      </Typography>
    )}
    {content}
    {subsections &&
      subsections.map((sub, index) => (
        <Box key={index} sx={{ marginTop: '2rem' }}>
          <Typography level="h6" gutterBottom sx={{ color: '#34495e' }}>
            {sub.subtitle}
          </Typography>
          {sub.content}
        </Box>
      ))}
  </Box>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.node.isRequired,
  subsections: PropTypes.array,
};

Section.defaultProps = {
  subtitle: null,
  subsections: null,
};

const PrivacyPolicy = () => {
  return (
    <Box
      sx={{
        fontFamily: 'Roboto, sans-serif',
        color: '#333',
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: { xs: '2rem 1rem', md: '4rem 2rem' },
        textAlign: 'left',
      }}
    >
      {/* Main Content Container */}
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          padding: { xs: '2rem', md: '3rem' },
        }}
      >
        {/* Title */}
        <Typography level="h3" align="center" gutterBottom sx={{ color: '#2c3e50', marginBottom: '2rem', fontWeight: 'bold' }}>
          Privacy Policy
        </Typography>

        {/* Sections */}
        <Section
          title="1. Introduction"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              AlgoInvestor Fund ("AlgoInvest," "we," "our," or "us") is committed to protecting your privacy and safeguarding your personal information. This Privacy Policy describes how we collect, use, disclose, and protect the personal information of our clients and website visitors.
            </Typography>
          }
        />

        <Section
          title="2. Information We Collect"
          subsections={[
            {
              subtitle: '2.1. Personal Information',
              content: (
                <>
                  <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                    We collect personal information that you provide directly to us, including:
                  </Typography>
                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Full name</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Date of birth</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Social Security number or Tax Identification Number</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Contact information (email address, phone number, mailing address)</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Financial information (income, net worth, investment goals)</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Employment information</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Bank/investment account information for funding your investment account</Typography>
                    </ListItem>
                  </List>
                </>
              ),
            },
            {
              subtitle: '2.2. Automatically Collected Information',
              content: (
                <>
                  <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                    We may automatically collect certain information about your device and how you interact with our Services, including:
                  </Typography>
                  <List sx={{ listStyleType: 'disc', pl: 4 }}>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>IP address</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Device type and operating system</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Browser type and version</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Usage data (pages visited, time spent on site, etc.)</Typography>
                    </ListItem>
                    <ListItem sx={{ display: 'list-item', pl: 0 }}>
                      <Typography>Location data</Typography>
                    </ListItem>
                  </List>
                </>
              ),
            },
            {
              subtitle: '2.3. Information from Third Parties',
              content: (
                <Typography level="body1" sx={{ marginBottom: '2rem' }}>
                  We may receive information about you from third parties, such as credit reporting agencies, identity verification services, and other financial institutions.
                </Typography>
              ),
            },
          ]}
        />

        <Section
          title="3. How We Use Your Information"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                We use your personal information for the following purposes:
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>To provide and maintain our Services</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>To create and manage your investment account</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>To comply with legal and regulatory requirements</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>To communicate with you about your account and our Services</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>To improve and personalize our Services</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>To detect and prevent fraud and other unauthorized activities</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>For internal research and analysis</Typography>
                </ListItem>
              </List>
            </>
          }
        />

        <Section
          title="4. Information Sharing and Disclosure"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                We may share your personal information in the following circumstances:
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>With service providers and partners who assist us in providing our Services</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>With regulatory authorities, law enforcement agencies, or other third parties when required by law or to protect our rights</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>In connection with a merger, acquisition, or sale of all or a portion of our assets</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>With your consent or at your direction</Typography>
                </ListItem>
              </List>
              <Typography level="body1">
                We do not sell your personal information to third parties.
              </Typography>
            </>
          }
        />

        <Section
          title="5. Data Security"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
            </Typography>
          }
        />

        <Section
          title="6. Your Rights and Choices"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                Depending on your location, you may have certain rights regarding your personal information, including:
              </Typography>
              <List sx={{ listStyleType: 'disc', pl: 4 }}>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>The right to access your personal information</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>The right to correct inaccurate or incomplete information</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>The right to request deletion of your personal information</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>The right to restrict or object to processing of your personal information</Typography>
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0 }}>
                  <Typography>The right to data portability</Typography>
                </ListItem>
              </List>
              <Typography level="body1">
                To exercise these rights, please contact us using the information provided in the "Contact Us" section.
              </Typography>
            </>
          }
        />

        <Section
          title="7. Retention of Personal Information"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
            </Typography>
          }
        />

        <Section
          title="8. Children's Privacy"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              Our Services are not directed to children under the age of 18. We do not knowingly collect personal information from children under 18. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us.
            </Typography>
          }
        />

        <Section
          title="9. Changes to This Privacy Policy"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last Updated" date at the top of this Privacy Policy.
            </Typography>
          }
        />

        <Section
          title="10. California Privacy Rights"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              If you are a California resident, you have certain rights under the California Consumer Privacy Act (CCPA). For more information about these rights and how to exercise them, please see our{' '}
              <Link href="#" underline="hover" color="primary">
                California Privacy Notice
              </Link>
              .
            </Typography>
          }
        />

        {/* Contact Us Section */}
        <Section
          title="11. Contact Us"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                If you have any questions about this Privacy Policy or our data practices, please contact us at:
              </Typography>
              <Typography level="body1" component="address" sx={{ lineHeight: 1.6 }}>
                AlgoInvestor Fund<br />
                14 Stony Brook Rd<br />
                Montville, NJ, 07045<br />
                Email: <Link href="mailto:support@algoinvest.io" color="primary">support@algoinvest.io</Link><br />
                Phone: (973) 567-2240
              </Typography>
            </>
          }
        />

        {/* Agreement Statement */}
        <Box
          sx={{
            fontWeight: 'bold',
            marginTop: '4rem',
            padding: '2rem',
            backgroundColor: '#f8f9fa',
            textAlign: 'center',
          }}
        >
          <Typography level="body1">
            By using our Services, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
