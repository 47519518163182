import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Link, List, ListItem } from '@mui/joy';

// Section Component (Reusable)
const Section = ({ title, content }) => (
  <Box sx={{ marginBottom: '4rem' }}>
    <Typography level="h4" gutterBottom sx={{ color: '#2c3e50', fontWeight: 'bold' }}>
      {title}
    </Typography>
    {content}
  </Box>
);

Section.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

const TermsAndConditions = () => {
  return (
    <Box
      sx={{
        fontFamily: 'Roboto, sans-serif',
        color: '#333',
        backgroundColor: '#f8f9fa',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: { xs: '2rem 1rem', md: '4rem 2rem' },
        textAlign: 'left',
      }}
    >
      {/* Main Content Container */}
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          padding: { xs: '2rem', md: '3rem' },
        }}
      >
        {/* Title */}
        <Typography level="h3" align="center" gutterBottom sx={{ color: '#2c3e50', marginBottom: '2rem', fontWeight: 'bold' }}>
          Terms and Conditions
        </Typography>

        {/* Introduction */}
        <Section
          title="1. Agreement to Terms"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              These Terms and Conditions ("Terms") constitute a legally binding agreement between you and AlgoInvestor Fund
              ("AlgoInvest," "we," "our," or "us"), governing your access to and use of our website, mobile application, and
              investment advisory services (collectively, the "Services"). By accessing or using our Services, you agree to
              be bound by these Terms. If you disagree with any part of the Terms, you may not access the Services.
            </Typography>
          }
        />

        {/* Definitions with numbered sub-sections */}
        <Section
          title="2. Definitions"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>2.1.</strong> "Client," "you," or "your" refers to the individual or entity that has registered for our Services.
              </Typography>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>2.2.</strong> "Account" means the account created when you register for our Services.
              </Typography>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>2.3.</strong> "ETFs" means Exchange-Traded Funds.
              </Typography>
            </>
          }
        />

        <Section
          title="3. Eligibility"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              To use our Services, you must be at least 18 years old and capable of forming a binding contract. By using our
              Services, you represent and warrant that you meet these eligibility requirements.
            </Typography>
          }
        />

        <Section
          title="4. Registration and Account Security"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>4.1.</strong> To access our Services, you must create an account by providing accurate, complete, and current information.
              </Typography>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>4.2.</strong> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.
              </Typography>
              <Typography level="body1">
                <strong>4.3.</strong> You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
              </Typography>
            </>
          }
        />

        {/* Prohibited Activities with numbered sub-sections */}
        <Section
          title="5. Prohibited Activities"
          content={
            <>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                You may not access or use the Site for any purpose other than that for which we make the Site available. The Site may not be used in connection with any commercial endeavors except those that are specifically endorsed or approved by us.
              </Typography>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>5.1.</strong> Systematically retrieve data or other content from the Site to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.
              </Typography>
              <Typography level="body1" sx={{ marginBottom: '1rem' }}>
                <strong>5.2.</strong> Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords.
              </Typography>
              <Typography level="body1">
                <strong>5.3.</strong> Circumvent, disable, or otherwise interfere with security-related features of the Site, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Site and/or the Content contained therein.
              </Typography>
            </>
          }
        />

        <Section
          title="6. Intellectual Property Rights"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the "Content") and the trademarks, service marks, and logos contained therein (the "Marks") are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Site "AS IS" for your information and personal use only. Except as expressly provided in these Terms, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.
            </Typography>
          }
        />

        <Section
          title="7. Governing Law and Jurisdiction"
          content={
            <Typography level="body1" sx={{ marginBottom: '2rem' }}>
              These Terms shall be governed by and construed in accordance with the laws of the State of Delaware, without regard to its conflict of law provisions. You agree to submit to the personal jurisdiction of the courts located in Wilmington, Delaware for the purpose of litigating all such claims or disputes.
            </Typography>
          }
        />

        {/* Agreement Statement */}
        <Box
          sx={{
            fontWeight: 'bold',
            marginTop: '4rem',
            padding: '2rem',
            backgroundColor: '#f8f9fa',
            textAlign: 'center',
          }}
        >
          <Typography level="body1">
            By using our Services, you acknowledge that you have read and understood these Terms and agree to be bound by them.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default TermsAndConditions;
