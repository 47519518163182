import React from 'react';
import { Box, Typography, Grid, Sheet } from '@mui/joy';
import PropTypes from 'prop-types';

const TaxLossHarvesting = () => {
  return (
    <Sheet
      sx={{
        padding: { xs: '2rem 1rem', md: '3rem 2rem' },
        margin: { xs: '2rem 1rem', md: '2rem auto' },
        maxWidth: '1000px',
        borderRadius: 'md',
        backgroundColor: '#fff',
        boxShadow: 'sm',
      }}
    >
      <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: 2 }}>
        Smart Tax-Loss Harvesting
      </Typography>
      <Typography level="body2" sx={{ lineHeight: 1.8, fontSize: '1.15rem', marginBottom: 4 }}>
        Our advanced algorithms continuously monitor your portfolio to identify and execute tax-loss harvesting opportunities. By strategically selling underperforming assets, we can offset gains in other investments, reducing your overall tax liability.
      </Typography>
      <Grid container spacing={4}>
        <Grid xs={12} sm={6} md={4}>
          <HarvestingCard title="What is Tax-Loss Harvesting?">
            Tax-loss harvesting is a strategy that involves selling investments at a loss to offset capital gains taxes from profitable investments. This helps reduce your overall tax bill while maintaining your investment strategy.
          </HarvestingCard>
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <HarvestingCard title="How We Use Tax-Loss Harvesting">
            Our algorithms automatically detect opportunities to sell underperforming assets. By doing so, we systematically offset gains in other areas of your portfolio, ensuring your investments remain optimized for tax efficiency.
          </HarvestingCard>
        </Grid>
        <Grid xs={12} sm={6} md={4}>
          <HarvestingCard title="Benefits for You">
            Implementing tax-loss harvesting can significantly lower your tax liabilities, enhance your after-tax returns, and help your portfolio grow more efficiently over time. It’s a smart way to keep more of your hard-earned money working for you.
          </HarvestingCard>
        </Grid>
      </Grid>
    </Sheet>
  );
};

export default TaxLossHarvesting;

// HarvestingCard Component
const HarvestingCard = ({ title, children }) => (
  <Sheet
    variant="outlined"
    sx={{
      backgroundColor: '#f9f9f9',
      padding: '1.5rem',
      borderRadius: 'md',
      boxShadow: 'sm',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#00509e', marginBottom: '1rem' }}>
      {title}
    </Typography>
    <Typography level="body2" sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8 }}>
      {children}
    </Typography>
  </Sheet>
);

HarvestingCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};