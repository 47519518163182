import React from 'react';
import { Box, Typography, List, ListItem, Sheet } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';

const Beta = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '50px',
        backgroundColor: '#f4f4f4',
        minHeight: '100vh',
      }}
    >
      {/* Internal Tab Bar */}
      <InternalTabBar />

      {/* Centered Content */}
      <Box
        sx={{
          maxWidth: '80%',
          margin: '0 auto',
          marginLeft: '17%', // Adjust to make space for the sidebar
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
        }}
      >
        <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
          Understanding Beta Factors in Investing
        </Typography>
        <Typography sx={{ marginBottom: 4, fontSize: '1.25rem', lineHeight: 1.8 }}>
          Learn how we use beta factors to build diversified portfolios that align with market dynamics.
        </Typography>

        {/* Beta Factors Section */}
        <BetaFactors />
      </Box>
    </Box>
  );
};

export default Beta;

// BetaFactors Component
const BetaFactors = () => (
  <Sheet
    sx={{
      padding: { xs: '2rem 1rem', md: '3rem 2rem' },
      margin: { xs: '2rem 1rem', md: '2rem auto' },
      maxWidth: '800px',
      borderRadius: 'md',
      backgroundColor: '#fff',
      boxShadow: 'sm',
    }}
  >
    <Typography sx={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: 3 }}>
      Beta (Systematic Risk) Factors
    </Typography>
    <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
      Beta factors represent the systematic risks that affect the overall market. By understanding and analyzing these factors, we construct portfolios that are aligned with market movements while managing exposure to various risks.
    </Typography>
    <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
      Our Beta product provides broad exposure to all major market risks through ETFs, including equities (US, Europe, Asia, etc.), fixed-income (government, corporate, etc.), commodities (energy, food, etc.), and real estate (residential, commercial, US, international). This diversified approach helps spread risk across various asset classes, allowing you to grow with the markets while avoiding drawdowns due to specific assets.
    </Typography>
    <List sx={{ '--List-itemPadding': '0.75rem 0' }}>
      <ListItem>
        <FactorDetail title="Market Factor" description="The overall return of the market." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Size Factor" description="Differences in returns between small-cap and large-cap companies." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Value Factor" description="Preference for undervalued stocks over overvalued ones." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Momentum Factor" description="Tendency of assets to continue in their current price trend." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Volatility Factor" description="Impact of an asset's volatility on its expected return." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Liquidity Factor" description="Effect of trading volume and liquidity on asset prices." />
      </ListItem>
    </List>
  </Sheet>
);

// Reusable FactorDetail component
const FactorDetail = ({ title, description }) => (
  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
    <Typography
      sx={{ width: '200px', fontWeight: '600', fontSize: '1rem', color: '#00509e' }}
    >
      {title}
    </Typography>
    <Typography sx={{ fontSize: '1rem', lineHeight: 1.6 }}>{description}</Typography>
  </Box>
);

// import React from 'react';
// import { Box, Typography, List, ListItem, Sheet } from '@mui/joy';
// import InternalTabBar from '../../components/tabs/InternalTabBar';

// const Beta = () => {
//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         paddingTop: '50px',
//         backgroundColor: '#f4f4f4',
//         minHeight: '100vh',
//       }}
//     >
//       {/* Internal Tab Bar */}
//       <InternalTabBar />

//       {/* Centered Content */}
//       <Box
//         sx={{
//           maxWidth: '80%',
//           margin: '0 auto',
//           marginLeft: '17%', // Adjust to make space for the sidebar
//           flexGrow: 1,
//           padding: { xs: 2, md: 4 },
//         }}
//       >
//         <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
//           Understanding Factors in Investing
//         </Typography>
//         <Typography sx={{ marginBottom: 4, fontSize: '1.25rem', lineHeight: 1.8 }}>
//           Learn how beta and alpha factors are used together to build robust, risk-conscious portfolios.
//         </Typography>

//         {/* Beta Factors Section */}
//         <BetaFactors />

//         {/* Alpha Factors Section */}
//         <AlphaFactors />

//         {/* Integrated Approach Section */}
//         <IntegratedApproach />
//       </Box>
//     </Box>
//   );
// };

// export default Beta;

// // BetaFactors Component
// const BetaFactors = () => (
//   <Sheet
//     sx={{
//       padding: { xs: '2rem 1rem', md: '3rem 2rem' },
//       margin: { xs: '2rem 1rem', md: '2rem auto' },
//       maxWidth: '800px',
//       borderRadius: 'md',
//       backgroundColor: '#fff',
//       boxShadow: 'sm',
//     }}
//   >
//     <Typography sx={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: 3 }}>
//       Beta (Risk) Factors
//     </Typography>
//     <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
//       Beta factors represent the systematic risks that affect the overall market. By understanding and analyzing these factors, we can construct portfolios that are aligned with market movements while managing exposure to various risks.
//     </Typography>
//     <List sx={{ '--List-itemPadding': '0.75rem 0' }}>
//       <ListItem>
//         <FactorDetail title="Market Factor:" description="The overall return of the market." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Size Factor:" description="Differences in returns between small-cap and large-cap." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Value Factor:" description="Preference for undervalued stocks over overvalued ones." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Momentum Factor:" description="Tendency of assets to continue in their current price trend." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Volatility Factor:" description="Impact of an asset's volatility on its expected return." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Liquidity Factor:" description="Effect of trading volume and liquidity on asset prices." />
//       </ListItem>
//     </List>
//   </Sheet>
// );

// // AlphaFactors Component
// const AlphaFactors = () => (
//   <Sheet
//     sx={{
//       padding: { xs: '2rem 1rem', md: '3rem 2rem' },
//       margin: { xs: '2rem 1rem', md: '2rem auto' },
//       maxWidth: '800px',
//       borderRadius: 'md',
//       backgroundColor: '#fff',
//       boxShadow: 'sm',
//     }}
//   >
//     <Typography sx={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: 3 }}>
//       Alpha Factors
//     </Typography>
//     <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
//       Alpha factors are used to identify opportunities for returns beyond those explained by beta factors. These are based on specific characteristics or insights about individual assets or sectors.
//     </Typography>
//     <List sx={{ '--List-itemPadding': '0.75rem 0' }}>
//       <ListItem>
//         <FactorDetail title="Quality Metrics:" description="Companies with strong balance sheets and profitability." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Earnings Surprises:" description="Stocks that outperform earnings expectations." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Analyst Upgrades:" description="Positive changes in analyst recommendations." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Industry Trends:" description="Favorable developments within specific industries." />
//       </ListItem>
//       <ListItem>
//         <FactorDetail title="Technical Indicators:" description="Patterns in trading data that suggest future movements." />
//       </ListItem>
//     </List>
//   </Sheet>
// );

// // IntegratedApproach Component
// const IntegratedApproach = () => (
//   <Sheet
//     sx={{
//       padding: { xs: '2rem 1rem', md: '3rem 2rem' },
//       margin: { xs: '2rem 1rem', md: '2rem auto' },
//       maxWidth: '800px',
//       borderRadius: 'md',
//       backgroundColor: '#fff',
//       boxShadow: 'sm',
//       marginBottom: { xs: '4rem', md: '4rem' },
//     }}
//   >
//     <Typography sx={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: 3 }}>
//       Integrating Beta and Alpha Factors
//     </Typography>
//     <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
//       By combining beta and alpha factors, we create portfolios that are both risk-conscious and positioned for outperformance. Our approach involves using beta factors to construct a solid foundation, ensuring diversification and alignment with market dynamics.
//     </Typography>
//     <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8 }}>
//       We then overlay alpha factors to adjust our positions within each asset class or sector, capitalizing on specific opportunities identified through our research and analysis.
//     </Typography>
//   </Sheet>
// );

// // Reusable FactorDetail component
// const FactorDetail = ({ title, description }) => (
//   <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
//     <Typography
//       sx={{ width: '200px', fontWeight: '600', fontSize: '1rem', color: '#00509e' }}
//     >
//       {title}
//     </Typography>
//     <Typography sx={{ fontSize: '1rem', lineHeight: 1.6 }}>{description}</Typography>
//   </Box>
// );