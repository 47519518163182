// src/components/Settings/AccountsSection.jsx

import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Modal,
  ModalDialog,
  ModalClose,
  Input,
  List,
  ListItem,
  ListItemDecorator,
  IconButton,
  Stack,
} from '@mui/joy';
import { FaUniversity, FaTrash } from 'react-icons/fa';
import { getAccounts, addAccount, removeAccount } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const AccountsSection = () => {
  const [accounts, setAccounts] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);

  useEffect(() => {
    fetchAccounts();
  }, []);

  const fetchAccounts = async () => {
    try {
      const data = await getAccounts();
      setAccounts(data);
    } catch (error) {
      console.error('Failed to fetch accounts:', error);
      toast.error('Failed to fetch accounts.');
    }
  };

  const accountFormik = useFormik({
    initialValues: {
      name: '',
      account_number: '',
      routing_number: '',
      account_type: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Bank/Brokerage Name is required'),
      account_number: Yup.string().required('Account Number is required'),
      routing_number: Yup.string().required('Routing Number is required'),
      account_type: Yup.string().required('Account Type is required'),
    }),
    onSubmit: async (values) => {
      try {
        await addAccount(values);
        toast.success('Account added successfully!');
        setOpenAddModal(false);
        accountFormik.resetForm();
        fetchAccounts();
      } catch (error) {
        console.error('Failed to add account:', error);
        toast.error('Failed to add account. Please try again.');
      }
    },
  });

  const handleRemoveAccount = async (id) => {
    if (window.confirm('Are you sure you want to remove this account?')) {
      try {
        await removeAccount(id);
        toast.success('Account removed successfully!');
        setAccounts(accounts.filter((account) => account.id !== id));
      } catch (error) {
        console.error('Failed to remove account:', error);
        toast.error('Failed to remove account. Please try again.');
      }
    }
  };

  const accountTypes = [
    { value: 'Checking', label: 'Bank: Checking' },
    { value: 'Savings', label: 'Bank: Savings' },
    // Add other account types as needed
  ];

  return (
    <Box>
      <Typography level="h5" gutterBottom>
        Connected Accounts
      </Typography>
      <List>
        {accounts.length > 0 ? (
          accounts.map((account) => (
            <ListItem
              key={account.id}
              endAction={
                <IconButton color="danger" onClick={() => handleRemoveAccount(account.id)}>
                  <FaTrash />
                </IconButton>
              }
            >
              <ListItemDecorator>
                <FaUniversity />
              </ListItemDecorator>
              <Typography>
                {account.name} ({account.account_type}) - ****{account.account_number.slice(-4)}
              </Typography>
            </ListItem>
          ))
        ) : (
          <Typography>No connected accounts.</Typography>
        )}
      </List>
      <Box mt={2}>
        <Button variant="solid" color="primary" onClick={() => setOpenAddModal(true)}>
          + Add Account
        </Button>
      </Box>

      {/* Add Account Modal */}
      <Modal open={openAddModal} onClose={() => setOpenAddModal(false)}>
        <ModalDialog aria-labelledby="add-account-dialog-title" size="md">
          <ModalClose />
          <Typography id="add-account-dialog-title" level="h6" component="h2">
            Add Account
          </Typography>
          <form onSubmit={accountFormik.handleSubmit}>
            <Stack spacing={2}>
              <Input
                fullWidth
                id="name"
                name="name"
                placeholder="Bank/Brokerage Name"
                value={accountFormik.values.name}
                onChange={accountFormik.handleChange}
                onBlur={accountFormik.handleBlur}
                error={accountFormik.touched.name && Boolean(accountFormik.errors.name)}
                helperText={accountFormik.touched.name && accountFormik.errors.name}
              />
              <Input
                fullWidth
                id="account_number"
                name="account_number"
                placeholder="Account Number"
                value={accountFormik.values.account_number}
                onChange={accountFormik.handleChange}
                onBlur={accountFormik.handleBlur}
                error={accountFormik.touched.account_number && Boolean(accountFormik.errors.account_number)}
                helperText={accountFormik.touched.account_number && accountFormik.errors.account_number}
              />
              <Input
                fullWidth
                id="routing_number"
                name="routing_number"
                placeholder="Routing Number"
                value={accountFormik.values.routing_number}
                onChange={accountFormik.handleChange}
                onBlur={accountFormik.handleBlur}
                error={accountFormik.touched.routing_number && Boolean(accountFormik.errors.routing_number)}
                helperText={accountFormik.touched.routing_number && accountFormik.errors.routing_number}
              />
              <Input
                fullWidth
                id="account_type"
                name="account_type"
                placeholder="Account Type"
                value={accountFormik.values.account_type}
                onChange={accountFormik.handleChange}
                onBlur={accountFormik.handleBlur}
                error={accountFormik.touched.account_type && Boolean(accountFormik.errors.account_type)}
                helperText={accountFormik.touched.account_type && accountFormik.errors.account_type}
                list="account-types"
              />
              <datalist id="account-types">
                {accountTypes.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
            </Stack>
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
              <Button onClick={() => setOpenAddModal(false)} variant="plain" color="neutral">
                Cancel
              </Button>
              <Button type="submit" variant="solid" color="primary" disabled={!accountFormik.isValid || !accountFormik.dirty}>
                Add Account
              </Button>
            </Box>
          </form>
        </ModalDialog>
      </Modal>
    </Box>
  );
};

export default AccountsSection;