import React from 'react';
import { Box, Typography, Link } from '@mui/joy';
import TabBar from '../../components/tabs/TabBar'; // Ensure this component is compatible with Joy UI
import logo from '../../assets/images/logo.png';

const ApplicationSubmitted = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
        fontFamily: 'Roboto, sans-serif',
      }}
    >

      {/* Content Container */}
      <Box
        sx={{
          flex: 1,
          maxWidth: '800px',
          margin: '0 auto',
          padding: { xs: '2rem', md: '3rem' },
          textAlign: 'center',
        }}
      >
        
        {/* Title */}
        <Typography
          level="h3"
          component="h1"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            marginBottom: '2rem',
            color: '#2c3e50',
            fontWeight: 'bold',
          }}
        >
          Application Submitted
        </Typography>

        {/* Body Content */}
        <Typography
          level="body1"
          sx={{
            fontSize: '1.1rem',
            color: '#333',
            marginBottom: '1.5rem',
            lineHeight: 1.6,
          }}
        >
          Thank you for applying to join AlgoInvest! We are excited to onboard you to the future of investments.
        </Typography>

        <Typography
          level="body1"
          sx={{
            fontSize: '1.1rem',
            color: '#333',
            marginBottom: '1.5rem',
            lineHeight: 1.6,
          }}
        >
          At AlgoInvest, we are a fully systematic market risk manager providing diversified portfolios across all asset classes accessible and liquid on public exchanges like the NYSE. We also incorporate elements of "alpha" typically found at hedge funds, while avoiding risky shorting or leverage strategies.
        </Typography>

        <Typography
          level="body1"
          sx={{
            fontSize: '1.1rem',
            color: '#333',
            marginBottom: '1.5rem',
            lineHeight: 1.6,
          }}
        >
          Our team is reviewing your application, and we'll get back to you shortly. If you have any questions, feel free to reach out to{' '}
          <Link
            href="mailto:support@algoinvest.io"
            sx={{ color: '#007BFF', textDecoration: 'none' }}
          >
            support@algoinvest.io
          </Link>
          .
        </Typography>
          {/* Logo */}
          <Link href="/" sx={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Algoinvest Logo" style={{ height: '36px' }} />
        </Link>
      </Box>
    </Box>
  );
};

export default ApplicationSubmitted;