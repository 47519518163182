import React from 'react';
import { Box, Typography, Link } from '@mui/joy';
import backgroundImage from '../../assets/images/background-image.png';

const AlgoInvest = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        position: 'relative',
        bgcolor: '#40826D',
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'text.secondary',
        textAlign: 'left',
        mt: '-64px', 
        overflow: 'hidden',
      }}
    >
      {/* Overlay */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
        }}
      />
      {/* Content */}
      <Box
        sx={{
          position: 'relative',
          zIndex: 2,
          maxWidth: '85%',
          px: 4,
          width: '100%',
        }}
      >
        <Typography
          level="display1"
          component="h1"
          sx={{
            fontSize: '4rem',
            fontWeight: 'bold',
            fontFamily: 'Linden Hill, serif',
            mb: 1,
            color: 'white',
          }}
        >
          Smart Investing,
        </Typography>
        <Typography
          level="display1"
          component="h2"
          sx={{
            fontSize: '4rem',
            fontWeight: 'bold',
            fontFamily: 'Linden Hill, serif',
            mb: 3,
            color: 'white',
          }}
        >
          Effortless Management
        </Typography>
        <Typography
          level="body1"
          sx={{
            fontSize: '1.6rem',
            mb: 4,
            lineHeight: 1.6,
            color: 'white',
            fontFamily: 'Linden Hill, serif',
            maxWidth: '600px',
          }}
        >
      Unlock financial freedom with algorithmic strategies tailored to be stable passive investment opportunities for retail investors.
    </Typography>

        <Link
            variant="solid"
            color="success"
            textColor="#40826D"
            underline="none"
            href="#cta"
            sx={{
              fontSize: '1.15rem',
              padding: '10px 20px',
              fontWeight: '650',
              bgcolor: '#ffffff',

              borderColor: '#40826D',
              borderRadius:'50px',
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: '#e6f7f2',
              },
            }}
          >
            Start Investing
          </Link>
      </Box>
    </Box>
  );
};

export default AlgoInvest;