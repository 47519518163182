// src/components/internal/CorrelationHeatmap.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Sheet } from '@mui/joy';

const CorrelationHeatmap = ({ correlations }) => {
  if (!correlations || correlations.length === 0) {
    return (
      <Typography level="body1" align="center">
        No correlation data available.
      </Typography>
    );
  }

  const headers = correlations[0];
  const dataRows = correlations.slice(1);

  // Function to determine cell background color based on correlation value
  const getCellColor = (value) => {
    if (typeof value !== 'number') return 'inherit';
    if (value >= 0.7) return '#ffcccc'; // High correlation
    if (value <= 0.3) return '#ccffcc'; // Low correlation
    return '#fff2cc'; // Medium correlation
  };

  return (
    <Box
      sx={{
        overflowX: 'auto',
        height: '100%',
      }}
    >
      {/* Table Header */}
      <Box
        sx={{
          display: 'flex',
          borderBottom: '1px solid #e0e0e0',
          backgroundColor: '#f2f2f2',
        }}
      >
        <Box sx={{ width: '150px', padding: '8px', textAlign: 'center' }}>
          {/* Empty Cell for Top-Left Corner */}
        </Box>
        {headers.slice(1).map((header, index) => (
          <Box
            key={index}
            sx={{
              flex: '1 1 100px',
              padding: '8px',
              textAlign: 'center',
              fontWeight: 'bold',
              color: '#2f3b52',
            }}
          >
            {header}
          </Box>
        ))}
      </Box>

      {/* Table Body */}
      {dataRows.map((row, rowIndex) => (
        <Box
          key={rowIndex}
          sx={{
            display: 'flex',
            borderBottom: '1px solid #e0e0e0',
          }}
        >
          {/* Row Header */}
          <Box
            sx={{
              width: '150px',
              padding: '8px',
              textAlign: 'center',
              backgroundColor: '#f2f2f2',
              fontWeight: 'bold',
              color: '#2f3b52',
            }}
          >
            {row[0]}
          </Box>
          {/* Row Data */}
          {row.slice(1).map((cell, cellIndex) => (
            <Box
              key={cellIndex}
              sx={{
                flex: '1 1 100px',
                padding: '8px',
                textAlign: 'center',
                backgroundColor: getCellColor(cell),
                color: cell >= 0.7 || cell <= 0.3 ? '#2f3b52' : '#333',
              }}
            >
              {typeof cell === 'number' ? cell.toFixed(2) : cell}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
};

// Define PropTypes for better type checking
CorrelationHeatmap.propTypes = {
  correlations: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  ).isRequired,
};

export default CorrelationHeatmap;