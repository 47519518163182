// utils/auth.js

export function isAuthenticated() {
  const accessToken = localStorage.getItem('access_token');
  return accessToken;
}

export function isPendingVerification() {
  const accessToken = localStorage.getItem('access_token');
  const isVerified = localStorage.getItem('is_verified_by_algoinvest') === 'true';
  const isEmailVerified = localStorage.getItem('is_email_verified') === 'true';
  return !(accessToken && isVerified && isEmailVerified);
}
  
export function logout() {
  localStorage.removeItem('access_token');
  localStorage.removeItem('refresh_token');
  localStorage.removeItem('user_id');
  localStorage.removeItem('is_email_verified');
  localStorage.removeItem('is_verified_by_algoinvest');
  localStorage.removeItem('user_name');
}
