import React from 'react';
import { Box, Container } from '@mui/joy';
import TabBar from '../../components/tabs/TabBar';
import AlgoInvest from './AlgoInvest';
import Systematic from './Systematic';
import CoreFund from './CoreFund';
import Approach from './Approach';
import Performance from './Performance';
import Steps from './Steps';
import Reviews from './Reviews';
import FAQ from './FAQ';
import Contact from './Contact';

const HomePage = () => {
  return (
    <Box sx={{ position: 'relative', bgcolor: 'background.body', overflow: 'hidden' }}>
      <TabBar />

      {/* AlgoInvest Section - Full width */}
      <AlgoInvest />
      <div id="auto-etf">
      <Systematic />
      </div>
      <div id="core-fund">
      <CoreFund />
      </div>
      <div id="philosophy">
        <Approach />
      </div>

      <div id="performance" sx={{ mb: 10 }}>
        <Performance />
      </div>

      <div id="steps" sx={{ mb: 10 }}>
        <Steps />
      </div>

      <div id="reviews">
        <Reviews />
      </div>

      <div id="faq">
        <FAQ />
      </div>
      <div id="cta">
        <Contact />
      </div>
    </Box>
  );
};

export default HomePage;