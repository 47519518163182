import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Grid, Sheet, FormControl, Select, Option } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PerformanceChart from '../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../components/charts/CorrelationHeatmap';
import PropTypes from 'prop-types';
import {
  generateInvestmentData,
  generateCorrelationMatrix,
  granularityOptions,
} from '../../data/assetClassData';

const AssetClassPage = ({ assetClass }) => {
  const { name, description, longDescription, subclasses, groups } = assetClass;
  const [selectedGroup, setSelectedGroup] = useState(subclasses && subclasses.length > 0 ? subclasses[0] : null);
  const [selectedSubgroup, setSelectedSubgroup] = useState(null);
  const [granularity, setGranularity] = useState('10Y');
  const [chartData, setChartData] = useState([]);
  const [correlationData, setCorrelationData] = useState([]);

  useEffect(() => {
    let updatedChartData = [];
    let updatedCorrelationData = [];

    if (selectedSubgroup) {
      // Subgroup is selected
      const subgroup = assetClass.groups[selectedGroup].subgroups[selectedSubgroup];

      // Generate performance data for each ETF
      updatedChartData = subgroup.etfs.map((etf) => ({
        label: etf.symbol,
        data: generateInvestmentData(granularity, 100000, etf.symbol),
      }));

      // Use subgroup's correlation data
      updatedCorrelationData = subgroup.correlations;
    } else if (selectedGroup) {
      // Only group is selected
      const subgroups = assetClass.groups[selectedGroup].subgroups;

      // Generate performance data for each subgroup
      updatedChartData = Object.keys(subgroups).map((subgroupName) => ({
        label: subgroupName.replace(/_/g, ' '),
        data: generateInvestmentData(granularity, 100000, subgroupName),
      }));

      // Generate correlation matrix between subgroups
      const subgroupNames = Object.keys(subgroups);
      updatedCorrelationData = generateCorrelationMatrix(subgroupNames);
    } else {
      // Neither group nor subgroup is selected
      updatedChartData = [
        {
          label: assetClass.name,
          data: generateInvestmentData(granularity, 100000, assetClass.name),
        },
      ];

      // Generate correlation matrix between groups
      const groupNames = Object.keys(assetClass.groups);
      updatedCorrelationData = generateCorrelationMatrix(groupNames);
    }

    setChartData(updatedChartData);
    setCorrelationData(updatedCorrelationData);
  }, [granularity, assetClass, selectedGroup, selectedSubgroup]);

  return (
    <Box sx={{ display: 'flex', paddingTop: '50px' }}>
      <InternalTabBar />
      <Box
        sx={{
          maxWidth: '89%',
          margin: '0 auto',
          marginLeft: '11%',
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
          textAlign: 'center',
        }}
      >
        <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
          {name} Asset Class
        </Typography>
        <Typography sx={{ marginBottom: 4, fontSize: '1.25rem' }}>{description}</Typography>
        
        <Box sx={{ marginBottom: 4 }}>
          <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8 }}>{longDescription}</Typography>

          {/* Time Range Selector */}
          <Box sx={{ display: 'flex', justifyContent: 'center', margin: 4 }}>
            <FormControl sx={{ minWidth: 250 }} size="lg">
              <Select
                placeholder="Select Time Period"
                value={granularity}
                onChange={(e, newValue) => setGranularity(newValue)}
                sx={{ bgcolor: '#fff', borderRadius: 'lg', padding: '8px' }}
              >
                {granularityOptions.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </FormControl>
          </Box>

          {/* Group Selection */}
          {subclasses && subclasses.length > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
              {subclasses.map((group) => (
                <Button
                  key={group}
                  variant={selectedGroup === group ? 'solid' : 'outlined'}
                  onClick={() => {
                    setSelectedGroup(group);
                    setSelectedSubgroup(null);
                  }}
                  sx={{ margin: 1 }}
                >
                  {group}
                </Button>
              ))}
            </Box>
          )}

          {/* Subgroup Selection */}
          {selectedGroup && (
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
              {Object.keys(groups[selectedGroup].subgroups).map((subgroup) => (
                <Button
                  key={subgroup}
                  variant={selectedSubgroup === subgroup ? 'solid' : 'outlined'}
                  onClick={() => setSelectedSubgroup(subgroup)}
                  sx={{ margin: 1 }}
                >
                  {subgroup.replace(/_/g, ' ')}
                </Button>
              ))}
            </Box>
          )}
        </Box>

        <Grid container spacing={4}>
          <Grid xs={12}>
            <Sheet
              variant="outlined"
              sx={{
                padding: 3,
                borderRadius: 'md',
                boxShadow: 'sm',
                height: { xs: 400, md: 600 },
                backgroundColor: '#fff',
              }}
            >
              <Typography sx={{ fontSize: '1.5rem', fontWeight: '500', marginBottom: 3 }}>Performance</Typography>
              <PerformanceChart
                data={chartData}
                granularity={granularity}
                yAxisLabel="Investment Value (USD)"
                tooltipFormat={(value) => `$${value.toFixed(2)}`}
              />
            </Sheet>
          </Grid>
          <Grid xs={12}>
            <Sheet
              variant="outlined"
              sx={{
                padding: 3,
                borderRadius: 'md',
                boxShadow: 'sm',
                height: { xs: 400, md: 600 },
                backgroundColor: '#fff',
              }}
            >
              <Typography sx={{ fontSize: '1.5rem', fontWeight: '500', marginBottom: 3 }}>Correlation Heatmap</Typography>
              <CorrelationHeatmap correlations={correlationData} />
            </Sheet>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

AssetClassPage.propTypes = {
  assetClass: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    longDescription: PropTypes.string.isRequired,
    subclasses: PropTypes.arrayOf(PropTypes.string),
    groups: PropTypes.object.isRequired,
  }).isRequired,
};

export default AssetClassPage;
