// src/components/Settings/ProfileSection.jsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Input, Button, Grid, Stack } from '@mui/joy';
import { FaSave, FaUndo } from 'react-icons/fa';
import { fetchUserData, updateUserData } from '../../utils/api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

const ProfileSection = () => {
  const [initialValues, setInitialValues] = useState(null);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserData();
        setInitialValues(data);
      } catch (error) {
        console.error('Failed to load user data:', error);
        toast.error('Failed to load user data.');
      }
    };

    loadUserData();
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues || {
      firstname: '',
      lastname: '',
      email: '',
      phone_number: '',
      street_addr: '',
      city_addr: '',
      state_addr: '',
      country_addr: '',
      zip_code: '',
      dob: '',
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required('First name is required'),
      lastname: Yup.string().required('Last name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      phone_number: Yup.string().required('Phone number is required'),
      street_addr: Yup.string().required('Street address is required'),
      city_addr: Yup.string().required('City is required'),
      state_addr: Yup.string().required('State is required'),
      country_addr: Yup.string().required('Country is required'),
      zip_code: Yup.string().required('ZIP code is required'),
      dob: Yup.date().required('Date of birth is required'),
    }),
    onSubmit: async (values) => {
      try {
        await updateUserData(values);
        toast.success('Profile updated successfully!');
        setIsChanged(false);
      } catch (error) {
        console.error('Failed to update user data:', error);
        toast.error('Failed to update profile. Please try again.');
      }
    },
  });

  useEffect(() => {
    if (initialValues) {
      const isDifferent = Object.keys(initialValues).some(
        (key) => initialValues[key] !== formik.values[key]
      );
      setIsChanged(isDifferent);
    }
  }, [formik.values, initialValues]);

  const handleReset = () => {
    formik.resetForm();
    setIsChanged(false);
    toast.info('Changes have been reset.');
  };

  if (!initialValues) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Typography level="h5" gutterBottom>
        Profile Information
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {['firstname', 'lastname', 'email', 'phone_number', 'street_addr', 'city_addr', 'state_addr', 'country_addr', 'zip_code', 'dob'].map((field, index) => (
            <Grid key={field} item xs={12} sm={index < 2 ? 6 : 12}>
              <Input
                fullWidth
                id={field}
                name={field}
                type={field === 'dob' ? 'date' : 'text'}
                label={field.replace('_', ' ').replace('addr', 'address').toUpperCase()}
                value={formik.values[field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched[field] && Boolean(formik.errors[field])}
                helperText={formik.touched[field] && formik.errors[field]}
              />
            </Grid>
          ))}
        </Grid>

        <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
          <Button
            type="submit"
            variant="solid"
            color="primary"
            startDecorator={<FaSave />}
            disabled={!isChanged}
          >
            Save Changes
          </Button>
          {isChanged && (
            <Button
              type="button"
              variant="outlined"
              color="neutral"
              startDecorator={<FaUndo />}
              onClick={handleReset}
            >
              Reset Changes
            </Button>
          )}
        </Stack>
      </form>
    </Box>
  );
};

export default ProfileSection;