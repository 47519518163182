import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Alert, Sheet } from '@mui/joy';
import Settings from '@mui/icons-material/Settings';
import ProfileHeader from './ProfileHeader';
import PortfolioCard from './PortfolioCard';
import RankedPerformanceETFs from './RankedPerformanceETFs';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import { fetchUserData } from '../../utils/api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import assetClassData from '../../data/assetClassData';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const data = await fetchUserData();
        setUserData(data);
        if (data.accounts?.length > 0) {
          setSelectedAccount(data.accounts[0]);
        }
      } catch (error) {
        toast.error('Failed to load user data.');
      } finally {
        setLoading(false);
      }
    };
    loadUserData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#F2F4F3' }}>
      {/* Internal Sidebar */}
      <InternalTabBar />

      {/* Main content */}
      <Box sx={{ flexGrow: 1, marginLeft: '250px', p: 3 }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} md={10}>
            {/* Profile Header */}
            <ProfileHeader
              userName={`${userData.firstname} ${userData.lastname}`}
              selectedAccount={selectedAccount}
              accounts={userData.accounts}
              onAccountChange={(accountId) =>
                setSelectedAccount(userData.accounts.find((acc) => acc.id === accountId))
              }
            />

            {/* Settings Button */}
            <Box sx={{ textAlign: 'left', marginBottom: '2rem' }}>
              <Button
                variant="outlined"
                color="neutral"
                component={RouterLink}
                to="/settings"
                startDecorator={<Settings />}
                sx={{
                  fontSize: '1.1rem',
                  padding: '8px 20px',
                  borderRadius: '30px',
                  fontWeight: '600',
                  textTransform: 'none',
                  '&:hover': {
                    backgroundColor: '#e6f7f2',
                  },
                }}
              >
                Go to Settings
              </Button>
            </Box>

            {/* Content based on accounts */}
            {userData.accounts?.length > 0 ? (
              <>
                {/* Portfolio */}
                <Sheet sx={{ mb: 3, p: 3, bgcolor: '#fff', borderRadius: 'md', boxShadow: 'sm' }}>
                  <PortfolioCard account={selectedAccount} />
                </Sheet>

                {/* Ranked ETFs */}
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Sheet sx={{ p: 3, bgcolor: '#fff', borderRadius: 'md', boxShadow: 'sm' }}>
                      <RankedPerformanceETFs
                        type="top"
                        etfData={assetClassData.equitiesData.groups}
                      />
                    </Sheet>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Sheet sx={{ p: 3, bgcolor: '#fff', borderRadius: 'md', boxShadow: 'sm' }}>
                      <RankedPerformanceETFs
                        type="bottom"
                        etfData={assetClassData.equitiesData.groups}
                      />
                    </Sheet>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Alert sx={{ textAlign: 'center', mt: 4 }} variant="soft" color="info">
                You currently have no investment accounts. Please add an account in your settings.
              </Alert>
            )}
          </Grid>
        </Grid>
        <ToastContainer />
      </Box>
    </Box>
  );
};

export default Profile;
