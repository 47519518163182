import React from 'react';
import { Box, Typography, Grid, Sheet, Button } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import PropTypes from 'prop-types';
import TaxLossHarvesting from './TaxLossHarvesting';

const TaxEfficiencies = () => {
  return (
    <Box
      sx={{
        fontFamily: 'Roboto, sans-serif',
        color: '#333',
        backgroundColor: '#f9f9f9',
        minHeight: '100vh',
        paddingTop: '50px',
      }}
    >
      {/* Internal Tab Bar */}
      <InternalTabBar />

      {/* Main content centered */}
      <Box sx={{ maxWidth: '80%', margin: '0 auto', marginLeft: '17%', flexGrow: 1, padding: { xs: 2, md: 4 } }}>
        <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
          Tax-Efficient Investing
        </Typography>
        <Typography sx={{ marginBottom: 4, fontSize: '1.25rem' }}>
          Choose the right investment approach to optimize your tax situation.
        </Typography>

        {/* Tax-Advantaged Accounts Section */}
        <TaxAdvantagedAccounts />

        {/* Brokerage Accounts Section */}
        <BrokerageAccounts />

        {/* Tax Loss Harvesting Section */}
        <TaxLossHarvesting />

        {/* CTA Section */}
        <CTASection />
      </Box>
    </Box>
  );
};

export default TaxEfficiencies;

// TaxAdvantagedAccounts Component
const TaxAdvantagedAccounts = () => (
  <Sheet
    sx={{
      padding: { xs: '2rem 1rem', md: '3rem 2rem' },
      margin: { xs: '2rem 1rem', md: '2rem auto' },
      maxWidth: '1000px',
      borderRadius: 'md',
      backgroundColor: '#fff',
      boxShadow: 'sm',
    }}
  >
    <Typography level="h5" sx={{ fontSize: '1.5rem', marginBottom: 2 }}>
      Tax-Advantaged Accounts
    </Typography>
    <Typography level="body2" sx={{ lineHeight: 1.8, fontSize: '1.15rem', marginBottom: 4 }}>
      Tax-advantaged accounts offer benefits like tax deductions, deferred taxes, or tax-free growth, helping you grow your wealth more efficiently.
    </Typography>
    <Grid container spacing={4}>
      <Grid xs={12} sm={6} md={4}>
        <AccountCard title="Pre-Tax 401(k)">
          Employer-sponsored retirement plan allowing pre-tax contributions, reducing your taxable income today. Taxes are paid upon withdrawal in retirement.
        </AccountCard>
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <AccountCard title="After-Tax 401(k)">
          Contributions are made after taxes, but earnings grow tax-deferred. Can be rolled over to a Roth IRA for potential tax-free withdrawals.
        </AccountCard>
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <AccountCard title="Roth IRA">
          Individual Retirement Account with after-tax contributions. Qualified withdrawals, including earnings, are tax-free in retirement.
        </AccountCard>
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <AccountCard title="Traditional IRA">
          Offers potential tax deductions on contributions and tax-deferred growth. Taxes are paid upon withdrawal in retirement.
        </AccountCard>
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <AccountCard title="Health Savings Account (HSA)">
          Provides triple tax advantages: tax-deductible contributions, tax-free growth, and tax-free withdrawals for qualified medical expenses.
        </AccountCard>
      </Grid>
    </Grid>
  </Sheet>
);

// BrokerageAccounts Component
const BrokerageAccounts = () => (
  <Sheet
    sx={{
      padding: { xs: '2rem 1rem', md: '3rem 2rem' },
      margin: { xs: '2rem 1rem', md: '2rem auto' },
      maxWidth: '1000px',
      borderRadius: 'md',
      backgroundColor: '#fff',
      boxShadow: 'sm',
    }}
  >
    <Typography level="h5" sx={{ fontSize: '1.5rem', marginBottom: 2 }}>
      Brokerage Accounts
    </Typography>
    <Typography level="body2" sx={{ lineHeight: 1.8, fontSize: '1.15rem', marginBottom: 4 }}>
      While these accounts don't offer tax advantages, they provide flexibility with fewer restrictions on contributions and withdrawals.
    </Typography>
    <Grid container spacing={4}>
      <Grid xs={12} sm={6} md={6}>
        <AccountCard title="Individual Account">
          Owned by one person, allowing full control over investment decisions and withdrawals. Capital gains and dividends are taxed annually.
        </AccountCard>
      </Grid>
      <Grid xs={12} sm={6} md={6}>
        <AccountCard title="Joint Account">
          Shared ownership between two or more individuals, commonly used by spouses. Offers combined investment efforts and shared tax responsibilities.
        </AccountCard>
      </Grid>
    </Grid>
  </Sheet>
);

// CTASection Component
const CTASection = () => (
  <Sheet
    sx={{
      padding: { xs: '2rem 1rem', md: '3rem 2rem' },
      margin: { xs: '2rem 1rem', md: '2rem auto' },
      maxWidth: '800px',
      borderRadius: 'md',
      backgroundColor: '#e6f2ff',
      textAlign: 'center',
      boxShadow: 'sm',
    }}
  >
    <Typography level="h5" sx={{ fontSize: '1.5rem', marginBottom: 2 }}>
      Start Investing Today
    </Typography>
    <Typography level="body2" sx={{ marginBottom: 4, fontSize: '1.15rem' }}>
      Ready to take control of your financial future? We're here to help you choose the right path.
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, flexWrap: 'wrap' }}>
      <Button
        variant="solid"
        color="primary"
        sx={{
          backgroundColor: '#00509e',
          padding: '8px 16px',
          fontSize: '1rem',
          '&:hover': { backgroundColor: '#003f7f' },
        }}
      >
        Get Started
      </Button>
      <Button
        variant="outlined"
        color="primary"
        sx={{
          borderColor: '#00509e',
          color: '#00509e',
          padding: '8px 16px',
          fontSize: '1rem',
          '&:hover': { backgroundColor: '#f0f0f0' },
        }}
      >
        Learn More
      </Button>
    </Box>
  </Sheet>
);

// AccountCard Component
const AccountCard = ({ title, children }) => (
  <Sheet
    variant="outlined"
    sx={{
      backgroundColor: '#f9f9f9',
      padding: '1.5rem',
      borderRadius: 'md',
      boxShadow: 'sm',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <Typography sx={{ fontSize: '1.25rem', fontWeight: '600', color: '#00509e', marginBottom: '1rem' }}>
      {title}
    </Typography>
    <Typography level="body2" sx={{ flexGrow: 1, fontSize: '1rem', lineHeight: 1.8 }}>
      {children}
    </Typography>
  </Sheet>
);

AccountCard.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
