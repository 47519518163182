export const API_BASE_URL =
    process.env.NODE_ENV === "production"
        ? "https://6kyf0pke00.execute-api.us-east-1.amazonaws.com/dev"
        : "http://127.0.0.1:8000";

export const APP_BASE_URLS =
    process.env.NODE_ENV === "production"
        ? [
              "https://www.algoinvest.io",
              "https://algoinvest.io",
              "https://algoinvest.io.s3-website-us-east-1.amazonaws.com",
          ]
        : ["http://localhost:3000"];