import React from 'react';
import { Box, Typography, List, ListItem, Sheet } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';

const Alpha = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '50px',
        backgroundColor: '#f4f4f4',
        minHeight: '100vh',
      }}
    >
      {/* Internal Tab Bar */}
      <InternalTabBar />

      {/* Centered Content */}
      <Box
        sx={{
          maxWidth: '80%',
          margin: '0 auto',
          marginLeft: '17%', // Adjust to make space for the sidebar
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
        }}
      >
        <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
          Understanding Alpha Factors in Investing
        </Typography>
        <Typography sx={{ marginBottom: 4, fontSize: '1.25rem', lineHeight: 1.8 }}>
          Discover how we use alpha factors to identify opportunities for outperformance in the market.
        </Typography>

        {/* Alpha Factors Section */}
        <AlphaFactors />
      </Box>
    </Box>
  );
};

export default Alpha;

// AlphaFactors Component
const AlphaFactors = () => (
  <Sheet
    sx={{
      padding: { xs: '2rem 1rem', md: '3rem 2rem' },
      margin: { xs: '2rem 1rem', md: '2rem auto' },
      maxWidth: '800px',
      borderRadius: 'md',
      backgroundColor: '#fff',
      boxShadow: 'sm',
    }}
  >
    <Typography sx={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: 3 }}>
      Alpha Factors
    </Typography>
    <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
      Alpha factors are used to identify opportunities for returns beyond those explained by beta factors. These are based on specific characteristics or insights about individual assets or sectors.
    </Typography>
    <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
      Our Alpha product overlays an expert view on top of our Beta strategies, aiming to beat the market through advanced statistical, machine learning, and AI algorithms. By identifying alpha factors specific to the assets we trade, we provide additional value by enhancing returns beyond standard market exposures, delivering superior performance tailored to sophisticated investment goals.
    </Typography>
    <List sx={{ '--List-itemPadding': '0.75rem 0' }}>
      <ListItem>
        <FactorDetail title="Quality Metrics" description="Companies with strong balance sheets and profitability." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Earnings Surprises" description="Stocks that outperform earnings expectations." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Analyst Upgrades" description="Positive changes in analyst recommendations." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Industry Trends" description="Favorable developments within specific industries." />
      </ListItem>
      <ListItem>
        <FactorDetail title="Technical Indicators" description="Patterns in trading data that suggest future movements." />
      </ListItem>
    </List>
  </Sheet>
);

// Reusable FactorDetail component
const FactorDetail = ({ title, description }) => (
  <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
    <Typography
      sx={{ width: '200px', fontWeight: '600', fontSize: '1rem', color: '#00509e' }}
    >
      {title}
    </Typography>
    <Typography sx={{ fontSize: '1rem', lineHeight: 1.6 }}>{description}</Typography>
  </Box>
);
