import React from 'react';
import { Box, Typography, Button, Stack, Link } from '@mui/joy';
import logo from '../../assets/images/logo-whiteout.png';

const Contact = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#40826D', // Updated background color to match Figma
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: { xs: 4, md: 8 },
        textAlign: 'center',
        width: '100%',
      }}
    >
      <Box sx={{ maxWidth: '800px', width: '100%', px: 4 }}>
        <img
          src={logo}
          alt="AlgoInvest Logo"
          style={{ width: '350px', marginBottom: '20px' }} // Logo size adjusted for better alignment
        />
        <Typography
          level="h4"
          sx={{
            fontSize: '3.5rem',
            color: '#ffffff',
            mb: 2,
            fontWeight: 'bold',
            letterSpacing: '0.5px', // Letter spacing for improved readability
          }}
        >
          Start investing <span style={{ color: '#000000' }}>today.</span> {/* Emphasis on "today" */}
        </Typography>
        <Stack
          direction="column"
          spacing={2}
          justifyContent="center"
          alignItems="center" // Center align items within the stack
          sx={{ mb: 3 }}
        >
          <Link
            href="/signup"
            variant="solid"
            underline="none"
            sx={{
              padding: '12px 20px',
              backgroundColor: '#ffffff',
              color: '#40826D',
              borderRadius: '30px', // Increased border radius for softer edges
              fontSize: '1.5rem',
              fontWeight: 'bold',
              width: '100%', // Full width for a block appearance
              maxWidth: '303px',
              textAlign: 'center', // Center text inside the button
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: '#e6f7f2', // Subtle hover effect to match the Figma design
              },
            }}
          >
            Sign up to access algo
          </Link>
          <Link
            href="mailto:support@algoinvest.io"
            variant="solid"
            underline="none"
            sx={{
              padding: '12px 20px',
              backgroundColor: '#ffffff',
              color: '#40826D',
              borderRadius: '30px',
              fontSize: '1.5rem',
              fontWeight: 'bold',
              width: '100%',
              maxWidth: '303px',
              textAlign: 'center',
              transition: 'background-color 0.3s ease, color 0.3s ease',
              '&:hover': {
                backgroundColor: '#e6f7f2',
              },
            }}
          >
           support@algoinvest.io
          </Link>
        </Stack>
        <Typography
          level="subtitle1"
          sx={{
            fontSize: '2rem',
            color: '#ffffff',
            fontStyle: 'italic', // Italicized for consistency with Figma
            mt: 1,
          }}
        >
          Making quant accessible to everyone.
        </Typography>
        {/* Disclaimer Section */}
        <Box sx={{ mt: 6, px: 4 }}>
          <Typography
            level="body2"
            sx={{
              fontSize: '0.875rem',
              color: '#ffffff',
              lineHeight: 1.5,
              textAlign: 'center',
            }}
          >
            Disclaimer: AlgoInvestor Fund (“algoinvest”) is an investment adviser registering with the Securities and Exchange Commission (“SEC”). By using this website, you accept and agree to AlgoInvestor’s Terms of Use and Privacy Policy. AlgoInvestor’s investment advisory services are available only to residents of the United States in jurisdictions where AlgoInvestor is registered. Nothing on this website should be considered an offer, solicitation of an offer, or advice to buy or sell securities or investment products. 
            Past performance is no guarantee of future results. Any historical returns, expected returns, or probability projections are hypothetical in nature and may not reflect actual future performance. Account holdings and other information provided are for illustrative purposes only and are not to be considered investment recommendations. The content on this website is for informational purposes only and does not constitute a comprehensive description of AlgoInvestor’s investment advisory services.
            © 2024 AlgoInvestor Fund. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
