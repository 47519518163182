import React from 'react';
import { Box, Typography, Button, Grid, Sheet } from '@mui/joy';
import { Check, TrendingUp, NotificationsActive } from '@mui/icons-material';
import coreFundVisual from '../../assets/images/core-fund-visual.png';

// 408-26D, 478-778

const CoreFund = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#40826D',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: { xs: 4, md: 6 },
        px: { xs: 2, md: 0 },
        width: '100%',
      }}
    >
      <Box sx={{ maxWidth: '1200px', width: '100%', textAlign: 'center' }}>
        <Typography level="h4" sx={{ fontSize: '3.5rem', color: 'white', fontWeight: 'bold', mb: 2 }}>
          Safety For Your Wallet
        </Typography>
        <Typography level="h5" sx={{ fontSize: '2.2rem', color: 'white', fontWeight: 'bold', mb: 2 }}>
          Trusted & Tested Algorithms
        </Typography>
        <Sheet
          variant="outlined"
          sx={{
            bgcolor: '#fff',
            borderRadius: '50px',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            display: 'flex',
            overflow: 'hidden',
            position: 'relative',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'center',
            maxWidth: '100%',
          }}
        >
          {/* Left Content Section */}
          <Box sx={{ flex: 1, p: { xs: '20px', md: '40px' }, textAlign: 'left' }}>
            <Typography level="h6" sx={{ fontSize: '2.5rem', fontWeight: 'bold', color: '#2f3b52', mb: 2 }}>
              Our Core Fund
            </Typography>
            <Typography
              level="body1"
              sx={{ color: '#5a6b7d', fontSize: '1.26rem', mb: 2, lineHeight: 1.6 }}
            >
              We eliminate the complexity of the investment process by only having one investment fund that we have
              been putting all of our money into for years. When you do well, we do well. This fund combines our beta, alpha, and rho products.
            </Typography>
            <Box component="ul" sx={{ listStyle: 'none', p: 0, m: 0 }}>
              <Box component="li" sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '1.15rem', color: '#2f3b52' }}>
                <Check sx={{ color: '#40826D', mr: 1 }} /> Easy to use
              </Box>
              <Box component="li" sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '1.15rem', color: '#2f3b52' }}>
                <TrendingUp sx={{ color: '#40826D', mr: 1 }} /> Constant earnings
              </Box>
              <Box component="li" sx={{ display: 'flex', alignItems: 'center', mb: 1, fontSize: '1.15rem', color: '#2f3b52' }}>
                <NotificationsActive sx={{ color: '#40826D', mr: 1 }} /> Responds to market dynamics
              </Box>
            </Box>
          </Box>
          {/* Right Content Section */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: '#f8f9fa',
              p: { xs: '20px', md: '40px' },
            }}
          >
            <img src={coreFundVisual} alt="Core Fund Visual" style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Sheet>
      </Box>
    </Box>
  );
};

export default CoreFund;