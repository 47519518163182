// src/components/Settings/Settings.jsx

import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Button, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import { FaUser, FaLock, FaUniversity } from 'react-icons/fa';
import ProfileSection from './ProfileSection';
import SecuritySection from './SecuritySection';
import AccountsSection from './AccountsSection';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Settings = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Box
      sx={{
        fontFamily: 'Roboto, sans-serif',
        backgroundColor: 'background.level1',
        minHeight: '100vh',
        padding: { xs: 2, md: 5 },
      }}
    >
      <Box
        sx={{
          maxWidth: 800,
          margin: '0 auto',
          backgroundColor: 'background.surface',
          borderRadius: 'md',
          boxShadow: 'md',
          padding: 3,
        }}
      >
        <Typography level="h4" gutterBottom>
          Settings
        </Typography>
        <Button
          component={RouterLink}
          to="/profile"
          variant="plain"
          color="neutral"
          startDecorator={<FaUser />}
          sx={{ mb: 3, fontSize: '16px' }}
        >
          Back to Profile
        </Button>

        <Tabs
          value={activeTab}
          onChange={(event, newValue) => handleTabChange(event, newValue)}
          sx={{ borderBottom: '1px solid', borderColor: 'divider' }}
        >
          <TabList>
            <Tab value={0}>
              <FaUser style={{ marginRight: 8 }} />
              Personal
            </Tab>
            <Tab value={1}>
              <FaLock style={{ marginRight: 8 }} />
              Security
            </Tab>
            <Tab value={2}>
              <FaUniversity style={{ marginRight: 8 }} />
              Accounts
            </Tab>
          </TabList>

          <TabPanel value={0} sx={{ padding: 3 }}>
            <ProfileSection />
          </TabPanel>
          <TabPanel value={1} sx={{ padding: 3 }}>
            <SecuritySection />
          </TabPanel>
          <TabPanel value={2} sx={{ padding: 3 }}>
            <AccountsSection />
          </TabPanel>
        </Tabs>
      </Box>
      <ToastContainer />
    </Box>
  );
};

export default Settings;