// src/theme.js
import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          500: '#4caf50',
          600: '#45a049',
        },
        danger: {
          600: '#f44336',
        },
        success: {
          500: '#4caf50',
        },
        // Add more color definitions as needed
      },
    },
  },
  // Add more theme customizations as needed
});

export default theme;
