import React from 'react';
import { Box, Typography, Sheet } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar';

const Rho = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        paddingTop: '50px',
        backgroundColor: '#f4f4f4',
        minHeight: '100vh',
      }}
    >
      {/* Internal Tab Bar */}
      <InternalTabBar />

      {/* Centered Content */}
      <Box
        sx={{
          maxWidth: '80%',
          margin: '0 auto',
          marginLeft: '17%', // Adjust to make space for the sidebar
          flexGrow: 1,
          padding: { xs: 2, md: 4 },
        }}
      >
        <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
          Understanding Risk-Free Investments with Rho
        </Typography>
        <Typography sx={{ marginBottom: 4, fontSize: '1.25rem', lineHeight: 1.8 }}>
          Explore how our Rho product offers a secure and reliable way to grow your savings without risk.
        </Typography>

        {/* Rho Section */}
        <RhoSection />
      </Box>
    </Box>
  );
};

export default Rho;

// RhoSection Component
const RhoSection = () => (
  <Sheet
    sx={{
      padding: { xs: '2rem 1rem', md: '3rem 2rem' },
      margin: { xs: '2rem 1rem', md: '2rem auto' },
      maxWidth: '800px',
      borderRadius: 'md',
      backgroundColor: '#fff',
      boxShadow: 'sm',
    }}
  >
    <Typography sx={{ fontSize: '1.75rem', fontWeight: '600', marginBottom: 3 }}>
      Rho: Risk-Free Investing
    </Typography>
    <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8, marginBottom: 2 }}>
      Our Rho product allows you to invest with us and experience a truly risk-free opportunity. This kind of product is commonly referred to as a high-yield savings account, effectively offering a "free money" proposition with zero risk at a predetermined rate like 4% annually.
    </Typography>
    <Typography sx={{ fontSize: '1.15rem', lineHeight: 1.8 }}>
      In recent times, accounts like these have reached rates as high as 5.5%! By choosing our Rho product, you can grow your savings securely, knowing your principal is protected while earning competitive interest rates.
    </Typography>
  </Sheet>
);
