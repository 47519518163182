import React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/joy';

const RankedPerformanceETFs = ({ type, etfData }) => {
  const allETFs = etfData
    ? Object.values(etfData).flatMap((group) =>
        Object.values(group.subgroups).flatMap((subgroup) => subgroup.etfs)
      )
    : [];

  const validETFs = allETFs.filter((etf) => etf.performance && typeof etf.performance['1Y'] === 'number');
  const sortedETFs = validETFs.sort((a, b) => b.performance['1Y'] - a.performance['1Y']);
  const displayETFs = type === 'top' ? sortedETFs.slice(0, 5) : sortedETFs.slice(-5).reverse();

  const title = type === 'top' ? 'Top Performing ETFs' : 'Worst Performing ETFs';

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        padding: 3,
        borderRadius: 'md',
        boxShadow: 'sm',
        height: '100%',
      }}
    >
      <Typography level="h6" sx={{ mb: 2, color: '#333', fontWeight: '600' }}>
        {title}
      </Typography>
      <Stack spacing={2} divider={<Divider />}>
        {displayETFs.map((etf) => (
          <Box
            key={etf.symbol}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingY: 1,
            }}
          >
            <Box sx={{ flexBasis: '25%', color: '#00509e' }}>
              <Typography sx={{ fontWeight: 'bold' }}>{etf.symbol}</Typography>
            </Box>
            <Box sx={{ flexBasis: '35%' }}>
              <Typography sx={{ color: '#666' }}>{etf.description}</Typography>
            </Box>
            <Box
              sx={{
                flexBasis: '20%',
                fontWeight: 'bold',
                color: etf.performance['1Y'] >= 0 ? '#2e7d32' : '#d32f2f', // Green for positive, Red for negative
              }}
            >
              {etf.performance['1Y'] >= 0 ? `+${etf.performance['1Y']}%` : `${etf.performance['1Y']}%`}
            </Box>
            <Box sx={{ flexBasis: '20%', color: '#333' }}>
              <Typography>{etf.price}</Typography>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default RankedPerformanceETFs;
