import React from 'react';
import { Box, Typography, Grid } from '@mui/joy';
import piggyBankIcon from '../../assets/images/piggy-bank-icon.png';
import graphIcon from '../../assets/images/graph-icon.png';

const Systematic = () => {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        bgcolor: '#f9f9f9',
        py: { xs: 4, md: 8 },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
      }}
    >
      {/* Feature 1 - Piggy Bank */}
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
          mb: { xs: 8, md: 12 },
        }}
      >
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={piggyBankIcon} alt="Piggy Bank Icon" style={{ maxWidth: '80%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography level="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#2f3b52', mb: 2 }}>
            Wealth Management Made Easy
          </Typography>
          <Typography level="body1" sx={{ fontSize: '1.8rem', color: '#40826D', fontWeight: 'bold', mb: 2 }}>
            Core Fund
          </Typography>
          <Typography level="body2" sx={{ fontSize: '1.45rem', color: '#5a6b7d', lineHeight: 1.6 }}>
            Our single core fund allows you to easily invest in ETFs that provide exposure to a wide range of asset
            classes, including equities (stocks), fixed-income (bonds), real estate, and commodities.
          </Typography>
        </Grid>
      </Grid>

      {/* Feature 2 - Graph */}
      <Grid
        container
        spacing={4}
        sx={{
          maxWidth: '1200px',
          width: '100%',
          margin: '0 auto',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={graphIcon} alt="Graph Icon" style={{ maxWidth: '80%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography level="h3" sx={{ fontSize: '2rem', fontWeight: 'bold', color: '#2f3b52', mb: 2 }}>
            Systematic Investment Process 
          </Typography>
          <Typography level="body1" sx={{ fontSize: '1.8rem', color: '#40826D', fontWeight: 'bold', mb: 2 }}>
            World Class Algos & Tech
          </Typography>
          <Typography level="body2" sx={{ fontSize: '1.45rem', color: '#5a6b7d', lineHeight: 1.6 }}>
            Using our sophisticated technology stack to process terabytes of data every day, we can carefully decompose the markets 
            and allocate capital across our Rho, Beta, and Alpha products to keep your money safe and growing.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Systematic;