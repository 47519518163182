import React, { useState, useEffect } from 'react';
import { Box, Typography, FormControl, Select, Option, Button } from '@mui/joy';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const PortfolioCard = ({ account }) => {
  const [granularity, setGranularity] = useState('10Y');
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    const data = generatePortfolioData(granularity, account.balance);
    setPortfolioData(data);
  }, [granularity, account.balance]);

  const chartData = {
    datasets: [
      {
        label: 'Portfolio Balance (USD)',
        data: portfolioData,
        fill: false,
        backgroundColor: '#00509e',
        borderColor: '#00509e',
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        type: 'time',
        time: {
          unit:
            granularity === '10Y' || granularity === '5Y'
              ? 'year'
              : granularity === '1Y' || granularity === '6M'
              ? 'month'
              : granularity === '1M'
              ? 'week'
              : 'day',
        },
        title: {
          display: true,
          text: 'Date',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Balance (USD)',
        },
      },
    },
  };

  return (
    <Box
      sx={{
        backgroundColor: '#fff',
        padding: 3,
        borderRadius: 'md',
        boxShadow: 'sm',
        mb: 4,
      }}
    >
      <Typography level="h5" gutterBottom>
        Portfolio Overview
      </Typography>
      <Typography level="h4" color="primary" gutterBottom>
        ${account.balance.toLocaleString()}
      </Typography>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 2,
          mb: 3,
        }}
      >
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            placeholder="View"
            value={granularity}
            onChange={(e, newValue) => setGranularity(newValue)}
          >
            <Option value="10Y">10 Years</Option>
            <Option value="5Y">5 Years</Option>
            <Option value="1Y">1 Year</Option>
            <Option value="6M">6 Months</Option>
            <Option value="1M">1 Month</Option>
            <Option value="1W">1 Week</Option>
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ height: { xs: 300, md: 500 } }}>
        <Line data={chartData} options={options} />
      </Box>
    </Box>
  );
};

// Helper function to generate random portfolio data for the chart
const generatePortfolioData = (granularity, targetEndValue) => {
  const now = new Date();
  let startDate;
  let points;

  switch (granularity) {
    case '10Y':
      startDate = new Date(now.getFullYear() - 10, now.getMonth(), now.getDate());
      points = 11;
      break;
    case '5Y':
      startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
      points = 6;
      break;
    case '1Y':
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      points = 13;
      break;
    case '6M':
      startDate = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate());
      points = 7;
      break;
    case '1M':
      startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
      points = 5;
      break;
    case '1W':
      startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
      points = 8;
      break;
    default:
      startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
      points = 13;
  }

  const data = [];
  let portfolioValue = targetEndValue / (1 + Math.random() * 0.2 - 0.1);

  for (let i = 0; i < points; i++) {
    const growthFactor = 1 + Math.random() * 0.05 - 0.02;
    portfolioValue = parseFloat((portfolioValue * growthFactor).toFixed(2));
    data.push({ x: new Date(startDate.setFullYear(startDate.getFullYear() + 1)), y: portfolioValue });
  }

  return data;
};

export default PortfolioCard;
