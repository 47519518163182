import React from 'react';
import { Box, Typography, Link, Button } from '@mui/joy';
import InternalTabBar from '../../components/tabs/InternalTabBar'; // Ensure this component is compatible with Joy UI
import {Link as RouterLink} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const PendingVerification = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
   };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f8f9fa',
        fontFamily: 'Roboto, sans-serif',
      }}
    >

      {/* Content Container */}
      <Box
        sx={{
          flex: 1,
          maxWidth: '800px',
          margin: '0 auto',
          padding: { xs: '2rem', md: '3rem' },
          textAlign: 'center',
        }}
      >
        {/* Title */}
        <Typography
          level="h3"
          component="h1"
          sx={{
            fontSize: { xs: '2rem', md: '2.5rem' },
            marginBottom: '2rem',
            color: '#2c3e50',
            fontWeight: 'bold',
          }}
        >
          Pending Verification
        </Typography>

        {/* Body Content */}
        <Typography
          level="body1"
          sx={{
            fontSize: '1.1rem',
            color: '#333',
            marginBottom: '1.5rem',
            lineHeight: 1.6,
          }}
        >
          Your application to join our platform is pending verification. We are reviewing your details and will get back to you shortly.
        </Typography>

        <Typography
          level="body1"
          sx={{
            fontSize: '1.1rem',
            color: '#333',
            marginBottom: '1.5rem',
            lineHeight: 1.6,
          }}
        >
          If you have any questions, please reach out to{' '}
          <Link
            href="mailto:support@algoinvest.io"
            sx={{ color: '#007BFF', textDecoration: 'none' }}
          >
            support@algoinvest.io
          </Link>
          .
        </Typography>
        <Button 
          onClick={handleLogout}
          component={RouterLink}
          textAlign="right"
          variant="solid"
          color="success"
          sx={{
            backgroundColor: 'success.500',
            '&:hover': { backgroundColor: 'success.600' },
            transition: 'background-color 0.3s ease',
          }}
        >
          Log Out
        </Button>
      </Box>
    </Box>
  );
};

export default PendingVerification;
