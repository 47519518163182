import React, { useState } from 'react';
import { Box, Typography, Button, Sheet, Stack } from '@mui/joy';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const testimonialsData = [
  {
    quote: "An innovative approach that truly revolutionizes investment science.",
    author: 'Albert Einstein',
    details: 'Theoretical Physicist',
  },
  {
    quote: "Finally, a platform that applies computational logic to investment.",
    author: 'Alan Turing',
    details: 'Mathematician and Computer Scientist',
  },
  {
    quote: "A platform that pushes the limits of our physical world.",
    author: 'Stephen Hawking',
    details: 'Theoretical Physicist and Cosmologist',
  },
  {
    quote: "A perfect blend of mathematics and market dynamics.",
    author: 'Jim Simons',
    details: 'Mathematician and Hedge Fund Manager',
  },
];

const Reviews = () => {
  const [current, setCurrent] = useState(0);

  const handlePrev = () => {
    setCurrent((prev) => (prev === 0 ? testimonialsData.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setCurrent((prev) => (prev === testimonialsData.length - 1 ? 0 : prev + 1));
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: '#40826D',
        py: { xs: 6, md: 10 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ maxWidth: '900px', width: '100%', textAlign: 'center' }}>
        <Typography level="h4" sx={{ fontSize: '1.8rem', color: '#ffffff', mb: 4 }}>
          Don't just believe us. Hear it from them.
        </Typography>
        <Box
          sx={{
            position: 'relative',
            backgroundColor: '#478778', 
            borderRadius: '20px', 
            p: { xs: 6, md: 8 },
            mx: 'auto',
            maxWidth: '800px',
            boxShadow: '0 8px 16px rgba(0, 0, 0, 0.15)',
          }}
        >
          <Button
            onClick={handlePrev}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '20px',
              transform: 'translateY(-50%)',
              background: 'transparent',
              border: 'none',
              fontSize: '2rem',
              color: '#ffffff',
              cursor: 'pointer',
              p: 0,
              minWidth: 0,
            }}
          >
            <ArrowBackIosIcon />
          </Button>
          <Sheet
            variant="plain"
            sx={{
              fontSize: '2.2rem',
              color: '#ffffff',
              backgroundColor: '#478778',
              fontWeight: 'bold',

              mx: 'auto',
              maxWidth: '700px',
            }}
          >
            “{testimonialsData[current].quote}”
            <Typography
              level="body2"
              sx={{ mt: 2, fontWeight: 'bold', fontSize: '1.5rem', color: '#ffffff' }}
            >
              {testimonialsData[current].author}
              <br />
              <span style={{ fontWeight: 'normal', fontSize: '1.25rem', fontStyle: 'italic'}}>
                {testimonialsData[current].details}
              </span>
            </Typography>
          </Sheet>
          <Button
            onClick={handleNext}
            sx={{
              position: 'absolute',
              top: '50%',
              right: '20px',
              transform: 'translateY(-50%)',
              background: 'transparent',
              border: 'none',
              fontSize: '2rem',
              color: '#ffffff', 
              cursor: 'pointer',
              p: 0,
              minWidth: 0,
            }}
          >
            <ArrowForwardIosIcon />
          </Button>
        </Box>
        <Stack direction="row" spacing={1} justifyContent="center" sx={{ mt: 4 }}>
          {testimonialsData.map((_, index) => (
            <Box
              key={index}
              sx={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor: current === index ? '#4B6652' : '#C4C4C4',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
              onClick={() => setCurrent(index)}
            />
          ))}
        </Stack>
      </Box>
    </Box>
  );
};

export default Reviews;