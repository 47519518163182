import React from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Box, Typography, Sheet } from '@mui/joy';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

const PerformanceChart = ({ data, granularity, yAxisLabel, tooltipFormat }) => {
  console.log("Received data in PerformanceChart:", data); // Debug log

  if (!Array.isArray(data) || data.length === 0 || !data.every(dataset => dataset && Array.isArray(dataset.data) && dataset.data.length > 0)) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography level="body1">No valid data available.</Typography>
      </Box>
    );
  }

  const colors = [
    '#4caf50', '#2196f3', '#ff9800', '#9c27b0', '#f44336', 
    '#009688', '#673ab7', '#3f51b5', '#8bc34a', '#ffc107',
    '#03a9f4', '#e91e63', '#cddc39', '#00bcd4', '#ff5722'
  ];

  const chartData = {
    datasets: data.map((dataset, index) => ({
      label: dataset.label,
      data: dataset.data,
      fill: false,
      backgroundColor: colors[index % colors.length],
      borderColor: colors[index % colors.length],
      tension: 0.1,
      pointRadius: 0,
      pointHoverRadius: 5,
    })),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#2f3b52',
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += tooltipFormat ? tooltipFormat(context.parsed.y) : `${context.parsed.y}`;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: granularity === '10Y' || granularity === '5Y' ? 'year' :
                granularity === '1Y' || granularity === '6M' ? 'month' :
                granularity === '1M' ? 'week' :
                granularity === '1W' ? 'day' : 'hour',
        },
        title: {
          display: true,
          text: 'Date',
          color: '#2f3b52',
        },
        ticks: {
          color: '#2f3b52',
        },
        grid: {
          color: '#e0e0e0',
        },
      },
      y: {
        title: {
          display: true,
          text: yAxisLabel,
          color: '#2f3b52',
        },
        ticks: {
          color: '#2f3b52',
          callback: function (value) {
            if (yAxisLabel.includes('%')) {
              return `${value}%`;
            }
            if (yAxisLabel.includes('USD')) {
              return `$${value}`;
            }
            return value;
          },
        },
        grid: {
          color: '#e0e0e0',
        },
      },
    },
  };

  return (
    <Sheet sx={{ padding: 2, borderRadius: 'md', boxShadow: 'sm', height: '100%', position: 'relative', backgroundColor: '#fff' }}>
      <Box sx={{ height: '100%' }}>
        <Line data={chartData} options={options} />
      </Box>
    </Sheet>
  );
};

PerformanceChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
          y: PropTypes.number.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
  granularity: PropTypes.string.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  tooltipFormat: PropTypes.func,
};

PerformanceChart.defaultProps = {
  tooltipFormat: null,
};

export default PerformanceChart;