import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, FormControl, Select, Option, Sheet } from '@mui/joy';
import PerformanceChart from '../../components/charts/PerformanceChart';
import CorrelationHeatmap from '../../components/charts/CorrelationHeatmap';
import InternalTabBar from '../../components/tabs/InternalTabBar';
import { granularityOptions, generateInvestmentData, getRandomStatistics } from '../../data/assetClassData';

const assetClasses = ['Core Fund', 'Equities', 'Fixed Income', 'Real Estate', 'Commodities'];

const CoreFundPerformance = () => {
 const [granularity, setGranularity] = useState('10Y');
 const [investmentData, setInvestmentData] = useState({});
 const [metrics, setMetrics] = useState({});


 useEffect(() => {
   const newInvestmentData = {};
   const newMetrics = {};


   assetClasses.forEach(assetClass => {
     const data = generateInvestmentData(granularity, 100000, assetClass);
     newInvestmentData[assetClass] = data;
     newMetrics[assetClass] = getRandomStatistics();
   });


   setInvestmentData(newInvestmentData);
   setMetrics(newMetrics);
 }, [granularity]);


 const chartData = Object.entries(investmentData).map(([label, data]) => ({ label, data }));


 const correlationMatrix = [
  ['-', ...assetClasses],
  ...assetClasses.map((asset) => [
    asset,
    ...assetClasses.map(() => parseFloat((Math.random() * (1 - (-1)) + (-1)).toFixed(2))),
  ]),
];

 return (
   <Box sx={{ display: 'flex', paddingTop: '50px' }}>
     <InternalTabBar />


     <Box sx={{ maxWidth: '89%', margin: '0 auto', marginLeft: '11%', flexGrow: 1, padding: { xs: 2, md: 4 }, textAlign: 'center' }}>
       <Typography sx={{ marginBottom: 2, fontSize: '2rem', fontWeight: '600' }}>
         Core Fund Performance
       </Typography>
       <Typography sx={{ marginBottom: 4, fontSize: '1.25rem' }}>
         Track the growth and performance of your Core Fund over various time periods.
       </Typography>


       <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 4 }}>
         <FormControl sx={{ minWidth: 250 }} size="lg">
           <Select
             placeholder="Select Time Period"
             value={granularity}
             onChange={(e, newValue) => setGranularity(newValue)}
             sx={{ bgcolor: '#fff', borderRadius: 'lg', padding: '8px' }}
           >
             {granularityOptions.map((option) => (
               <Option key={option.value} value={option.value}>
                 {option.label}
               </Option>
             ))}
           </Select>
         </FormControl>
       </Box>


       <Box sx={{ height: { xs: 400, md: 600 }, marginBottom: 4 }}>
         <PerformanceChart
           data={chartData}
           granularity={granularity}
           yAxisLabel="Investment Value (USD)"
           tooltipFormat={(value) => `$${value.toFixed(2)}`}
         />
       </Box>


       <Grid container spacing={3} sx={{ marginBottom: 4 }}>
         {Object.entries(metrics).map(([key, { returns, volatility, sharpe }]) => (
           <Grid xs={12} sm={6} md={2.4} key={key}>
             <Sheet variant="outlined" sx={{ backgroundColor: '#fff', boxShadow: 'sm', borderRadius: 'md', padding: 3, textAlign: 'center' }}>
               <Typography level="subtitle2" color="#5a6b7d" sx={{ fontSize: '1.15rem', fontWeight: '500' }}>
                 {key}
               </Typography>
               <Typography sx={{ fontSize: '1.15rem' }}>Returns: {returns}</Typography>
               <Typography sx={{ fontSize: '1.15rem' }}>Volatility: {volatility}</Typography>
               <Typography sx={{ fontSize: '1.15rem' }}>Sharpe: {sharpe}</Typography>
             </Sheet>
           </Grid>
         ))}
       </Grid>


       <Box sx={{ marginBottom: 2 }}>
         <Typography sx={{ fontSize: '1.5rem', fontWeight: '600', marginBottom: 2 }}>
           Asset Class Correlations
         </Typography>
         <CorrelationHeatmap correlations={correlationMatrix} />
       </Box>


       <Sheet sx={{ backgroundColor: '#e6f2ff', padding: { xs: 4, md: 8 }, borderRadius: 'md', margin: '0 auto', maxWidth: '100%' }}>
         <Typography sx={{ fontSize: '2rem', fontWeight: '600', marginBottom: 3 }}>
           How It Works
         </Typography>
         <Typography sx={{ fontSize: '1.25rem', lineHeight: 1.8 }}>
           We use a hierarchical risk budgeting approach to allocate your investments across different asset classes. This ensures that risk is balanced and diversified, helping to protect your portfolio against market volatility.
           By combining beta and alpha factors, we create portfolios that are both risk-conscious and positioned for outperformance. Our approach involves using beta factors to construct a solid foundation, ensuring diversification and alignment with market dynamics.
          We then overlay alpha factors to adjust our positions within each asset class or sector, capitalizing on specific opportunities identified through our research and analysis.
         </Typography>
       </Sheet>
     </Box>
   </Box>
 );
};


export default CoreFundPerformance;