import React from 'react';
import { Box, Typography, Sheet } from '@mui/joy';

const Steps = () => {
  const steps = [
    'Create an account',
    'Learn more about our approach',
    'Connect your accounts to our platform',
    'Make your first deposit',
    'Monitor your portfolio performance',
    'Map out your retirement trajectory',
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        bgcolor: '#f9f9f9',
        py: { xs: 6, md: 10 },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: { xs: 2, md: 4 },
      }}
    >
      <Box sx={{ maxWidth: '800px', width: '100%', textAlign: 'center' }}>
        <Typography
          level="h4"
          sx={{ fontSize: { xs: '2rem', md: '3rem' }, color: '#2f3b52', mb: 2, fontWeight: 'bold' }}
        >
          Simple & Efficient
        </Typography>
        <Typography
          level="h5"
          sx={{ fontSize: { xs: '1.2rem', md: '2rem' }, color: '#2f3b52', mb: 6, fontWeight: '450' }}
        >
          Navigating AlgoInvest
        </Typography>
        <Box>
          {steps.map((step, index) => (
            <Sheet
              key={index}
              variant="outlined"
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: '#40826D',
                borderRadius: '50px',
                p: { xs: '16px', md: '20px' },
                mb: 3,
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateX(8px)',
                  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                },
                flexDirection: 'row',
                textAlign: 'left',
              }}
            >
              <Box
                sx={{
                  bgcolor: '#40826D',
                  color: '#fff',
                  py: '8px',
                  px: '16px',
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  fontSize: '1.15rem',
                  minWidth: '70px',
                  textAlign: 'center',
                  mr: { xs: 2, md: 4 },
                }}
              >
                STEP {index + 1}
              </Box>
              <Typography level="body1" sx={{ flexGrow: 1, fontSize: '1.4rem', color: '#ffffff' }}>
                {step}
              </Typography>
            </Sheet>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Steps;