// components/PrivateRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, isPendingVerification } from '../../utils/auth';

const PrivateRoute = ({ element }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }
  if (isPendingVerification()) {
    return <Navigate to="/pending-verification" replace />;

  } else {
    return element;
  }
};

export default PrivateRoute;
