// components/PublicRoute.js

import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../../utils/auth';

const PublicRoute = ({ element }) => {
  return !isAuthenticated() ? element : <Navigate to="/profile" replace />;
};

export default PublicRoute;
